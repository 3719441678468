import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import img from "../../../assets/images/InceptionFinicallyLogo.png";
import styles from "./index.module.css";
import CustomSwitch from "../../CustomSwitch";
import { proformaFormSchema } from "../../../yup";
import { useGetStatesInfoQuery } from "../../../services/api";
import Select from "react-select";
import { config } from "../../../configs/index";
import { useNavigate } from "react-router-dom";
import { resetProformaUser } from "../../../store/reducers/proformaReducer";
import { useDispatch, useSelector } from "react-redux";
import useProformaValues from "../../../hooks/useSetProformaValues";
import Loader from "../../Loader";
import ProformaResponse from "../../ProformaResponse";
import { RootState } from "../../../store/store";
import {
  useGetDefaultProformaValuesQuery,
  useGetBaselineModalQuery,
  useGetContactGroupQuery,
  useSendProformaEmailMutation,
} from "../../../services/proformaApi";

interface IToggleSwitch {
  isAmpVantageActive: boolean;
  isUseAvailableTaxCredit: boolean;
  isUseAdjustedGrossIncome: boolean;
  isLossLimitation: boolean;
  isFederalRevenueSpread: boolean;
  isFederalBonusDepreciation: boolean;
  [field: string]: boolean;
}
interface State {
  _id: string;
  state: string;
}

interface StateOption {
  label: string;
  value: string;
}
const ProformaForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setProformaValues } = useProformaValues();
  const [toggleSwitch, setToggleSwitch] = useState<IToggleSwitch>({
    isAmpVantageActive: false,
    isUseAvailableTaxCredit: false,
    isUseAdjustedGrossIncome: false,
    isLossLimitation: true,
    isFederalRevenueSpread: false,
    isFederalBonusDepreciation: false,
  });
  const profromaUserDetails = useSelector(
    (state: RootState) => state.proformaUser
  );
  const [isExternal, setIsExternal] = useState(
    profromaUserDetails?.designation === "External"
  );
  const [stateTaxRatePercent, setStateTaxRatePercent] = useState<number>();
  const isReallyExternal = profromaUserDetails?.designation === "External";
  const {
    watch,
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(proformaFormSchema),
    mode: "onChange",
    context: {
      isEquity:
        !toggleSwitch.isUseAvailableTaxCredit &&
        !toggleSwitch.isUseAdjustedGrossIncome,
      isGross: toggleSwitch.isUseAdjustedGrossIncome,
      isITC: !toggleSwitch.isUseAvailableTaxCredit,
      isExternal: isExternal,
    },
  });
  const { data: states, isLoading: loading } = useGetStatesInfoQuery();
  const { data: defaultValues } = useGetDefaultProformaValuesQuery();
  const { data: baselineModalData } = useGetBaselineModalQuery();
  const { data: contactGroupData } = useGetContactGroupQuery();
  const [sendProformaEmail] = useSendProformaEmailMutation();

  const [stateOptions, setStateOptions] = useState<StateOption[]>();
  const [selectedContactName, setSelectedContactName] = useState(
    "Inception Financial"
  );
  const [contactGroupOptions, setContactGroupOptions] =
    useState<StateOption[]>();
  const [months] = useState([
    ...Array.from({ length: 12 }, (_, i) => `${i + 1}`),
  ]);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [driveFolderId, setDriveFolderId] = useState<DriveIdReturn>({});
  const [dataToPrefill, setDataToPrefill] = useState<string[][]>([]);

  const getStates = () => {
    const data = states?.data || [];
    const stateOption: StateOption[] = data.map((item: State) => {
      return {
        label: item.state,
        value: item._id,
      };
    });
    setStateOptions(stateOption);
  };

  const handleClick = (field: string) => {
    let newObj = { ...toggleSwitch, [field]: !toggleSwitch[field] };
    if (field === "isAmpVantageActive") {
      newObj.isLossLimitation = !newObj.isAmpVantageActive;
      newObj.isFederalRevenueSpread = newObj.isAmpVantageActive;
      if (newObj.isAmpVantageActive === true) {
        setValue(
          "ITCPercentage",
          `${baselineModalData?.data?.ampVantageItcPercent!}%`
        );
      } else {
        setValue("ITCPercentage", `${baselineModalData?.data?.itcPercent!}%`);
      }
    }
    setToggleSwitch(newObj);
  };

  const onSubmit = async (values: ProformaForm) => {
    if (!toggleSwitch.isUseAdjustedGrossIncome) {
      setValue("adjustedGrossIncome", "");
    }
    if (!toggleSwitch.isUseAvailableTaxCredit) {
      setValue("ITCAmount", "");
    }
    if (
      toggleSwitch.isUseAvailableTaxCredit ||
      toggleSwitch.isUseAdjustedGrossIncome
    ) {
      setValue("equityContribution", "$100000");
    }
    if (!toggleSwitch.isFederalRevenueSpread) {
      setValue("monthlyPlacedInService", "");
    }
    if (!toggleSwitch.isFederalBonusDepreciation) {
      setValue("federalBonusDepreciationPercentage", "");
    }
    const stateName = watch("stateSelector");
    const targetState = states?.data.filter((state) => {
      return state.state === stateName.label;
    });
    stateName.value &&
      isExternal &&
      setBaseLineValuesWithoutPartnerMarkup(targetState);
    if (isExternal) {
      const newInceptionDevFee =
        parseFloat(getValues("inceptionDevFee").replace(/%/g, "")) +
        parseFloat(values.partnerMarkup!.replace(/%/g, ""));
      // setValue("inceptionDevFee", `${newInceptionDevFee.toString()}%`);
      values.inceptionDevFee = `${newInceptionDevFee.toString()}%`;
    }
    try {
      setIsLoadingSubmit(true);
      const valuesToUpdate = setProformaValues(values, toggleSwitch);
      let proformaUrl = "";
      if (toggleSwitch.isAmpVantageActive) {
        const data = {
          driveId: config.REACT_APP_AMP_VANTAGE_FOLDER_ID,
          additionalPdfFolderId: config.REACT_APP_AMP_VANTAGE_PDF_FOLDER_ID,
          fileId: config.REACT_APP_MAIN_SPREAD_SHEET_ID,
          folderName: values.clientName,
          values: valuesToUpdate,
          scenarioName: values.scenarioName,
          slideId: config.REACT_APP_SLIDE_ID,
        };
        const response = await fetch(
          `${config.REACT_APP_BASE_URL}/api/proforma/create-ampvantage`,
          {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const res = await response.json();
        console.log(res, "res");
        setDriveFolderId(res);
        proformaUrl = res.folderId!;
      } else {
        console.log("internal or external");
        const folderIdtoSave = isReallyExternal
          ? profromaUserDetails?.folderId
          : "";

        const data = {
          driveId: defaultValues?.data[0]?.folderId!,
          externalUserFolderId: folderIdtoSave,
          fileId: config.REACT_APP_MAIN_SPREAD_SHEET_ID,
          folderName: values.clientName,
          values: valuesToUpdate,
          masterFileId: config.REACT_APP_MASTER_FILE_ID,
          scenarioName: values.scenarioName,
        };
        const response = await fetch(
          `${config.REACT_APP_BASE_URL}/api/proforma/create-proforma-copy`,
          {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const res = await response.json();
        setDriveFolderId(res.data);

        proformaUrl = isReallyExternal
          ? res?.data?.externalClientFolderID!
          : res?.data?.folderId!;
      }
      const body: ISendProformaEmail = {
        contactGroupId:
          contactGroupOptions?.filter(
            (item) => item.label === selectedContactName
          )![0]?.value || "",
        clientName: values.clientName,
        proformaUrl: proformaUrl,
      };
      const response = await sendProformaEmail(body).unwrap();
      if (!response.success)
        throw new Error("Failed to send email to contact groups");
    } catch (error) {
      console.log("Login error:", error);
      setErrMsg("An error occurred during login.");
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | boolean } }
  ) => {
    const { name, value } = e.target;
    if (name === "year1DepreciationUsed") {
      let rawValue = value
        .toString()
        .replace("$0", "")
        .replace(/[^\d.]/g, "");
      let formattedValue = "";
      if (rawValue === "") {
        formattedValue = `$0${rawValue}`;
      } else {
        formattedValue = `-$${rawValue}`;
      }
      setValue(name, formattedValue);
    } else if (
      name === "stateTaxRate" ||
      name === "stateTaxRateYr7to20" ||
      name === "statePTEPayment" ||
      name === "federalBonusDepreciationPercentage" ||
      name === "federalMarginalTaxRate" ||
      name === "inceptionDevFee" ||
      name === "ITCPercentage" ||
      name === "developerDevFee" ||
      name === "partnerMarkup"
    ) {
      let rawValue = value
        .toString()
        .replace("%", "")
        .replace(/[^\d.]/g, "");
      // const formattedValue = `${rawValue}%`;
      let formattedValue = "";
      if (rawValue === "") {
        formattedValue = `${rawValue}0%`;
      } else {
        if (rawValue[0] === "0") rawValue = rawValue.substring(1);
        formattedValue = `${rawValue}%`;
      }
      // console.log("111111");
      setValue(name, formattedValue);
    } else {
      setValue(name as keyof ProformaForm, value);
    }
    setErrMsg("");
  };
  const setInputFieldsOnStateChange = (selectedOption: StateOption) => {
    if (!states?.data.length) {
      return;
    }
    const targetState = states?.data.filter((state) => {
      return state._id === selectedOption.value;
    });
    // isExternal &&
    setBaseLineValues(targetState);
    setStateTaxRatePercent(targetState![0].stateTaxRatePercent);
    setValue(
      "stateTaxRate",
      `${targetState![0].stateTaxRatePercent.toString()}%`
    );
    setValue(
      "stateTaxRateYr7to20",
      `${targetState![0].stateTaxRatePercentYrs7_20.toString()}%`
    );
    setValue(
      "statePTEPayment",
      `${targetState![0].statePTEPaymentPercent.toString()}%`
    );
    setValue(
      "stateBonusDepreciation",
      targetState![0].stateBonusDepreciation === "NO" ? false : true
    );
    setValue(
      "stateBonusDepreciationAsFED",
      targetState![0].stateDepreciation === "NO" ? false : true
    );
    setValue(
      "stateRevenueSpread",
      targetState![0].stateRevenueSpread === "NO" ? false : true
    );
  };
  const getAssumptionData = async () => {
    const spreadsheetId = config.REACT_APP_MAIN_SPREAD_SHEET_ID;

    try {
      setIsLoading(true);
      const response = await fetch(
        `${config.REACT_APP_BASE_URL}/api/proforma/get-assumptions-data/${spreadsheetId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      setDataToPrefill(result.data);
    } catch (error) {
      console.log("Login error:", error);
      setErrMsg("An error occurred during login.");
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    const currentDate = new Date();
    // console.log("2222");
    reset({
      year1DepreciationUsed: dataToPrefill[13][1] || "$0", // need assumption api to prefill this value
      stateSelector: {
        label: "California",
        value: "California",
      },
      federalMarginalTaxRate: "37%",
      monthlyPlacedInService:
        currentDate.getMonth() + 1 === 12
          ? "12"
          : String(currentDate.getMonth() + 2),
      federalBonusDepreciationPercentage: `${baselineModalData?.data?.federalBonusDepreciation}%`,
      developerDevFee: isReallyExternal
        ? `${profromaUserDetails.developerDevFee}%`
        : `${baselineModalData?.data?.developerDevFee}%`,
      ITCPercentage: isReallyExternal
        ? `${profromaUserDetails?.itcPercent}%`
        : toggleSwitch.isAmpVantageActive
        ? `${baselineModalData?.data.ampVantageItcPercent}%`
        : `${baselineModalData?.data?.itcPercent}%`,
      contactGroup: contactGroupOptions?.filter(
        (item) => item.label === selectedContactName
      ),
    });
    setInputFieldsOnStateChange({
      label: "California",
      value: "65d829be7389fe6c0ee54682",
    });
    let newObj = { ...toggleSwitch };
    newObj.isLossLimitation = baselineModalData?.data?.lossLimitation!;
    newObj.isFederalRevenueSpread =
      baselineModalData?.data?.federalRevenueSpread!;
    newObj.isFederalBonusDepreciation =
      dataToPrefill[32][1] === "Yes" ? true : false;
    setToggleSwitch(newObj);
    const targetState = states?.data.filter((state) => {
      return state.state === "California";
    });
    setStateTaxRatePercent(targetState![0].stateTaxRatePercent);
    isExternal && setBaseLineValues(targetState);
  };

  const setBaseLineValues = (targetState: any) => {
    const baseData = baselineModalData?.data;
    const isAmp = selectedContactName === "AmpVantage";
    const selectedContactGroup = contactGroupData?.data.filter(
      (item) => item.contactGroupName === selectedContactName
    );
    const taxRate = targetState![0].stateTaxRatePercent;
    if (
      taxRate >= baseData?.minStateTaxRateL! &&
      taxRate <= baseData?.maxStateTaxRateL!
    ) {
      !isAmp &&
        setValue(
          "inceptionDevFee",
          `${baseData?.inceptionDevFeeL.toString()}%`
        );
      if (isReallyExternal) {
        setValue("partnerMarkup", profromaUserDetails.lowScenerio + "%");
      } else
        setValue("partnerMarkup", selectedContactGroup![0].lowScenerio + "%");
    } else if (
      taxRate > baseData?.minStateTaxRateLM! &&
      taxRate <= baseData?.maxStateTaxRateLM!
    ) {
      !isAmp &&
        setValue(
          "inceptionDevFee",
          `${baseData?.inceptionDevFeeLM.toString()}%`
        );
      if (isReallyExternal) {
        setValue("partnerMarkup", profromaUserDetails.lowMidScenerio + "%");
      } else
        setValue(
          "partnerMarkup",
          selectedContactGroup![0].lowMidScenerio + "%"
        );
    } else if (
      taxRate > baseData?.minStateTaxRateHM! &&
      taxRate <= baseData?.maxStateTaxRateHM!
    ) {
      !isAmp &&
        setValue(
          "inceptionDevFee",
          `${baseData?.inceptionDevFeeHM.toString()}%`
        );
      if (isReallyExternal) {
        setValue("partnerMarkup", profromaUserDetails.highMidScenerio + "%");
      } else
        setValue(
          "partnerMarkup",
          selectedContactGroup![0].highMidScenerio + "%"
        );
    } else {
      !isAmp &&
        setValue(
          "inceptionDevFee",
          `${baseData?.inceptionDevFeeH.toString()}%`
        );
      if (isReallyExternal) {
        setValue("partnerMarkup", profromaUserDetails.highScenerio + "%");
      } else
        setValue("partnerMarkup", selectedContactGroup![0].highScenerio + "%");
    }
  };
  const setBaseLineValuesWithoutPartnerMarkup = (targetState: any) => {
    const baseData = baselineModalData?.data;
    const selectedContactGroup = contactGroupData?.data.filter(
      (item) => item.contactGroupName === selectedContactName
    );
    const taxRate = targetState![0].stateTaxRatePercent;
    if (
      taxRate >= baseData?.minStateTaxRateL! &&
      taxRate <= baseData?.maxStateTaxRateL!
    ) {
      setValue("inceptionDevFee", `${baseData?.inceptionDevFeeL.toString()}%`);
    } else if (
      taxRate > baseData?.minStateTaxRateLM! &&
      taxRate <= baseData?.maxStateTaxRateLM!
    ) {
      setValue("inceptionDevFee", `${baseData?.inceptionDevFeeLM.toString()}%`);
    } else if (
      taxRate > baseData?.minStateTaxRateHM! &&
      taxRate <= baseData?.maxStateTaxRateHM!
    ) {
      setValue("inceptionDevFee", `${baseData?.inceptionDevFeeHM.toString()}%`);
    } else {
      setValue("inceptionDevFee", `${baseData?.inceptionDevFeeH.toString()}%`);
    }
  };

  useEffect(() => {
    getStates();
  }, [states]);

  useEffect(() => {
    getAssumptionData();
  }, []);
  useEffect(() => {
    if (dataToPrefill.length > 0 && stateOptions) {
      resetForm();
    }
  }, [dataToPrefill, stateOptions, isReallyExternal, isExternal]);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(resetProformaUser());
    navigate("/proforma");
  };

  useEffect(() => {
    const contactOptions = () => {
      const data = contactGroupData?.data.map((item) => {
        return {
          label: item.contactGroupName,
          value: item._id!,
        };
      });
      setContactGroupOptions(data);
    };
    contactGroupData && contactOptions();
  }, [contactGroupData]);

  const handleContactGroupChange = (data: StateOption) => {
    if (data.label === "Inception Financial") {
      let newObj = { ...toggleSwitch, isAmpVantageActive: false };
      newObj.isLossLimitation = baselineModalData?.data?.lossLimitation!;
      newObj.isFederalRevenueSpread = newObj.isAmpVantageActive;
      // console.log("33333");

      setValue("ITCPercentage", `${baselineModalData?.data?.itcPercent!}%`);
      setValue(
        "inceptionDevFee",
        `${defaultValues?.data?.[0]?.inceptionDevFee}%`
      );
      setToggleSwitch(newObj);
      setIsExternal(false);
    } else if (data.label === "AmpVantage") {
      let newObj = { ...toggleSwitch, isAmpVantageActive: true };
      newObj.isLossLimitation = !newObj.isAmpVantageActive;
      newObj.isFederalRevenueSpread = newObj.isAmpVantageActive;
      // console.log("44444");
      setValue(
        "ITCPercentage",
        `${baselineModalData?.data?.ampVantageItcPercent!}%`
      );
      setValue("inceptionDevFee", `27.5%`);
      setToggleSwitch(newObj);
      setIsExternal(false);
    } else {
      setIsExternal(true);
      let newObj = { ...toggleSwitch, isAmpVantageActive: false };
      newObj.isLossLimitation = baselineModalData?.data?.lossLimitation!;
      newObj.isFederalRevenueSpread =
        baselineModalData?.data?.federalRevenueSpread!;
      setToggleSwitch(newObj);
      const selectedContactGroup = contactGroupData?.data.filter(
        (item) => item._id === data.value
      );

      const baseData = baselineModalData?.data;
      const taxRate = stateTaxRatePercent || 0;
      if (
        taxRate >= baseData?.minStateTaxRateL! &&
        taxRate <= baseData?.maxStateTaxRateL!
      ) {
        setValue("partnerMarkup", selectedContactGroup![0].lowScenerio + "%");
      } else if (
        taxRate > baseData?.minStateTaxRateLM! &&
        taxRate <= baseData?.maxStateTaxRateLM!
      ) {
        setValue(
          "partnerMarkup",
          selectedContactGroup![0].lowMidScenerio + "%"
        );
      } else if (
        taxRate > baseData?.minStateTaxRateHM! &&
        taxRate <= baseData?.maxStateTaxRateHM!
      ) {
        setValue(
          "partnerMarkup",
          selectedContactGroup![0].highMidScenerio + "%"
        );
      } else {
        setValue("partnerMarkup", selectedContactGroup![0].highScenerio + "%");
      }
    }
  };

  useEffect(() => {
    if (
      defaultValues?.data?.[0] &&
      profromaUserDetails?.designation === "Internal"
    ) {
      console.log(
        defaultValues.data[0],
        "defaultValues.data[0].inceptionDevFee"
      );
      reset({ inceptionDevFee: `${defaultValues.data[0].inceptionDevFee}%` });
    }
  }, [defaultValues]);

  useEffect(() => {}, [driveFolderId]);
  if (driveFolderId && Object.keys(driveFolderId).length > 0) {
    return (
      <ProformaResponse
        href={driveFolderId}
        setDriveFolderId={setDriveFolderId}
        resetForm={resetForm}
      />
    );
  }

  console.log("ITCPercentage", getValues("ITCPercentage"));

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <div>
          <img src={img} alt="" className={`${styles.image}`} />
        </div>
        {/* {profromaUserDetails?.designation !== "External" && (
          <div className={styles.switchBox}>
            <span className={styles.span}>AmpVantage</span>
            <CustomSwitch
              isActive={toggleSwitch.isAmpVantageActive}
              names="isAmpVantageActive"
              handleClick={handleClick}
            />
          </div>
        )} */}
        <div>
          {/* button */}
          <button
            onClick={handleLogout}
            className={`relative h-12 bg-[#3B755E] cursor-pointer rounded ${styles.logoutButton}`}
          >
            LOGOUT
          </button>
        </div>
      </div>
      {isLoading ? (
        <Loader isLoading={isLoading} size={48} />
      ) : (
        <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
          {isLoadingSubmit ? (
            <>
              <h1 className={`font-bold text-center ${styles.formHeader}`}>
                Generating your proforma
              </h1>
              <div className={styles.loaderBox}>
                <Loader isLoading={isLoadingSubmit} />
              </div>
            </>
          ) : (
            <>
              <h1 className={`font-bold text-center ${styles.formHeader}`}>
                Fill Out Your Information
              </h1>
              {!isReallyExternal && (
                <div className={`${styles.formLineFull}`}>
                  <label htmlFor="contactGroup" className={styles.inputLabel}>
                    Contact Group:
                  </label>
                  <Controller
                    name="contactGroup"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="contactGroup"
                        isLoading={loading}
                        className={`!p-0 ${styles.controller}`}
                        options={contactGroupOptions}
                        onChange={(selectedOption) => {
                          if (
                            !field.value ||
                            field.value.value !== selectedOption.value
                          ) {
                            handleContactGroupChange(selectedOption);
                          }
                          setSelectedContactName(selectedOption.label);
                          field.onChange(selectedOption);
                        }}
                      />
                    )}
                  />
                </div>
              )}

              <div className={styles.formLineFull}>
                <p className={styles.inputLabel}>Client Name*</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="clientName"
                    className={styles.inputBox}
                    {...register("clientName")}
                  />
                  {errors.clientName && (
                    <p className={styles.error}>{errors.clientName.message}</p>
                  )}
                </div>
              </div>
              <div className={styles.formLineFull}>
                <p className={styles.inputLabel}>Scenario Name*</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="scenarioName"
                    className={styles.inputBox}
                    {...register("scenarioName")}
                  />
                  {errors.scenarioName && (
                    <p className={styles.error}>
                      {errors.scenarioName.message}
                    </p>
                  )}
                </div>
              </div>
              <div className={styles.formLineFull}>
                <p className={styles.inputLabel}>Equity Contribution</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="equityContribution"
                    className={styles.inputBox}
                    disabled={
                      toggleSwitch.isUseAvailableTaxCredit ||
                      toggleSwitch.isUseAdjustedGrossIncome
                    }
                    {...register("equityContribution")}
                  />
                  {errors.equityContribution && (
                    <p className={styles.error}>
                      {errors.equityContribution.message}
                    </p>
                  )}
                </div>
              </div>
              <div
                className={`${
                  toggleSwitch.isAmpVantageActive ||
                  !toggleSwitch.isUseAvailableTaxCredit
                    ? "!hidden"
                    : ""
                } ${styles.formLineFull}`}
              >
                <p className={styles.inputLabel}>ITC Amount</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="ITCAmount"
                    className={styles.inputBox}
                    {...register("ITCAmount")}
                  />
                  {errors.ITCAmount && (
                    <p className={styles.error}>{errors.ITCAmount.message}</p>
                  )}
                </div>
              </div>
              <div className={`${styles.formLineFull}`}>
                <span className={styles.inputLabel}>
                  Use Adjusted Gross Income*
                </span>
                <CustomSwitch
                  isActive={toggleSwitch.isUseAdjustedGrossIncome}
                  names="isUseAdjustedGrossIncome"
                  handleClick={handleClick}
                />
              </div>
              <div
                className={`${
                  !toggleSwitch.isUseAdjustedGrossIncome ? "!hidden" : ""
                } ${styles.formLineFull}`}
              >
                <p className={styles.inputLabel}>Adjusted Gross Income</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="adjustedGrossIncome"
                    className={styles.inputBox}
                    {...register("adjustedGrossIncome")}
                  />
                  {errors.adjustedGrossIncome && (
                    <p className={styles.error}>
                      {errors.adjustedGrossIncome.message}
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.formLineFull}`}>
                <span className={styles.inputLabel}>Loss Limitation*</span>
                <CustomSwitch
                  isActive={toggleSwitch.isLossLimitation}
                  names="isLossLimitation"
                  handleClick={handleClick}
                />
              </div>
              {!isExternal && (
                <>
                  <div className={`${styles.formLineFull}`}>
                    <span className={styles.inputLabel}>
                      Federal Revenue Spread*
                    </span>
                    <CustomSwitch
                      isActive={toggleSwitch.isFederalRevenueSpread}
                      names="isFederalRevenueSpread"
                      handleClick={handleClick}
                    />
                  </div>
                  <div
                    className={`${
                      !toggleSwitch.isFederalRevenueSpread ? "!hidden" : ""
                    } ${styles.formLineFull}`}
                  >
                    <label
                      htmlFor="monthlyPlacedInService"
                      className={styles.inputLabel}
                    >
                      Month Placed In Service:
                    </label>
                    <Controller
                      name="monthlyPlacedInService"
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          id="monthlyPlacedInService"
                          className={styles.controller}
                        >
                          {months.map((month, index) => (
                            <option key={index} value={month}>
                              {month}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {errors.monthlyPlacedInService && (
                      <p>{errors.monthlyPlacedInService.message}</p>
                    )}
                  </div>
                  <div className={`${styles.formLineFull}`}>
                    <span className={styles.inputLabel}>
                      Federal Bonus Depreciation*
                    </span>
                    <CustomSwitch
                      isActive={toggleSwitch.isFederalBonusDepreciation}
                      names="isFederalBonusDepreciation"
                      handleClick={handleClick}
                    />
                  </div>
                  <div
                    className={`${styles.formLineFull} ${
                      !toggleSwitch.isFederalBonusDepreciation ? "!hidden" : ""
                    }`}
                  >
                    <p className={styles.inputLabel}>
                      Federal Bonus Depreciation %
                    </p>
                    <div className={styles.inputContainer}>
                      <input
                        type="text"
                        id="federalBonusDepreciationPercentage"
                        className={styles.inputBox}
                        {...register("federalBonusDepreciationPercentage")}
                        onChange={onChange}
                      />
                      {errors.federalBonusDepreciationPercentage && (
                        <p className={styles.error}>
                          {errors.federalBonusDepreciationPercentage.message}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className={`${styles.formLineFull}`}>
                <label htmlFor="stateSelector" className={styles.inputLabel}>
                  Select a State:
                </label>
                <Controller
                  name="stateSelector"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="stateSelector"
                      isLoading={loading}
                      className={`!p-0 ${styles.controller}`}
                      options={stateOptions}
                      // options={stateOptions as OptionsOrGroups<string, GroupBase<string>>}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                        setInputFieldsOnStateChange(selectedOption);
                      }}
                    />
                  )}
                />
                {/* {errors.stateSelector && <p>{errors.stateSelector.message}</p>} */}
              </div>
              <div className={`${styles.formLineFull}`}>
                <p className={styles.inputLabel}>State Tax Rate</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="stateTaxRate"
                    className={styles.inputBox}
                    {...register("stateTaxRate")}
                    onChange={onChange}
                  />
                  {errors.stateTaxRate && (
                    <p className={styles.error}>
                      {errors.stateTaxRate.message}
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.formLineFull}`}>
                <p className={styles.inputLabel}>State Tax Rate Yr 7-20</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="stateTaxRateYr7to20"
                    className={styles.inputBox}
                    {...register("stateTaxRateYr7to20")}
                    onChange={onChange}
                  />
                  {errors.stateTaxRateYr7to20 && (
                    <p className={styles.error}>
                      {errors.stateTaxRateYr7to20.message}
                    </p>
                  )}
                </div>
              </div>
              {!isExternal && (
                <>
                  <div className={`${styles.formLineFull}`}>
                    <p className={styles.inputLabel}>State PTE Payment %</p>
                    <div className={styles.inputContainer}>
                      <input
                        type="text"
                        id="statePTEPayment"
                        className={styles.inputBox}
                        {...register("statePTEPayment")}
                        onChange={onChange}
                      />
                      {errors.statePTEPayment && (
                        <p className={styles.error}>
                          {errors.statePTEPayment.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.formLineFull}`}>
                    <label
                      htmlFor="stateBonusDepreciation"
                      className={styles.inputLabel}
                    >
                      State Bonus Depreciation
                    </label>
                    <Controller
                      name="stateBonusDepreciation"
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          id="stateBonusDepreciation"
                          className={styles.controller}
                          value={field.value === true ? "true" : "false"}
                          onChange={(e) => {
                            const booleanValue = e.target.value === "true";
                            onChange({
                              target: { name: field.name, value: booleanValue },
                            });
                            field.onChange(booleanValue);
                          }}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      )}
                    />
                    {errors.stateBonusDepreciation && (
                      <p>{errors.stateBonusDepreciation.message}</p>
                    )}
                  </div>
                  <div className={`${styles.formLineFull}`}>
                    <label
                      htmlFor="stateBonusDepreciationAsFED"
                      className={styles.inputLabel}
                    >
                      State Depreciation Same As FED Basis
                    </label>
                    <Controller
                      name="stateBonusDepreciationAsFED"
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          id="stateBonusDepreciationAsFED"
                          className={styles.controller}
                          value={field.value === true ? "true" : "false"}
                          onChange={(e) => {
                            const booleanValue = e.target.value === "true";
                            onChange({
                              target: { name: field.name, value: booleanValue },
                            });
                            field.onChange(booleanValue);
                          }}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      )}
                    />
                    {errors.stateBonusDepreciationAsFED && (
                      <p>{errors.stateBonusDepreciationAsFED.message}</p>
                    )}
                  </div>
                  <div className={`${styles.formLineFull}`}>
                    <label
                      htmlFor="stateRevenueSpread"
                      className={styles.inputLabel}
                    >
                      State Revenue Spread
                    </label>
                    <Controller
                      name="stateRevenueSpread"
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          id="stateRevenueSpread"
                          className={styles.controller}
                          value={field.value === true ? "true" : "false"}
                          onChange={(e) => {
                            const booleanValue = e.target.value === "true";
                            onChange({
                              target: { name: field.name, value: booleanValue },
                            });
                            field.onChange(booleanValue);
                          }}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      )}
                    />
                    {errors.stateRevenueSpread && (
                      <p>{errors.stateRevenueSpread.message}</p>
                    )}
                  </div>{" "}
                </>
              )}
              {!isExternal ? (
                <>
                  <div className={`${styles.formLineFull}`}>
                    <p className={styles.inputLabel}>ITC %</p>
                    <div className={styles.inputContainer}>
                      <input
                        type="text"
                        id="ITCPercentage"
                        className={styles.inputBox}
                        {...register("ITCPercentage")}
                        onChange={onChange}
                      />
                      {errors.ITCPercentage && (
                        <p className={styles.error}>
                          {errors.ITCPercentage.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.formLineFull}`}>
                    <p className={styles.inputLabel}>Inception Dev Fee</p>
                    <div className={styles.inputContainer}>
                      <input
                        type="text"
                        id="inceptionDevFee"
                        className={styles.inputBox}
                        {...register("inceptionDevFee")}
                        onChange={onChange}
                      />
                      {errors.inceptionDevFee && (
                        <p className={styles.error}>
                          {errors.inceptionDevFee.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.formLineFull}`}>
                    <p className={styles.inputLabel}>Developer Dev Fee</p>
                    <div className={styles.inputContainer}>
                      <input
                        type="text"
                        id="developerDevFee"
                        className={styles.inputBox}
                        {...register("developerDevFee")}
                        onChange={onChange}
                      />
                      {errors.developerDevFee && (
                        <p className={styles.error}>
                          {errors.developerDevFee.message}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className={`${styles.formLineFull}`}>
                  <p className={styles.inputLabel}>Partner Markup %</p>
                  <div className={styles.inputContainer}>
                    <input
                      type="text"
                      id="partnerMarkup"
                      className={styles.inputBox}
                      {...register("partnerMarkup")}
                      onChange={onChange}
                    />
                    {errors.partnerMarkup && (
                      <p className={styles.error}>
                        {errors.partnerMarkup.message}
                      </p>
                    )}
                  </div>
                </div>
              )}
              <div className={`${styles.formLineFull}`}>
                <p className={styles.inputLabel}>Federal Marginal Tax Rate</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="federalMarginalTaxRate"
                    className={styles.inputBox}
                    {...register("federalMarginalTaxRate")}
                    onChange={onChange}
                  />
                  {errors.federalMarginalTaxRate && (
                    <p className={styles.error}>
                      {errors.federalMarginalTaxRate.message}
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.formLineFull} !justify-center`}>
                <button
                  type="submit"
                  className={`relative cursor-pointer rounded ${styles.logoutButton}`}
                >
                  SUBMIT
                </button>
              </div>
            </>
          )}
        </form>
      )}
    </div>
  );
};

export default ProformaForm;
