import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import {
  useUpdateMeetingMutation,
  useUpdateStagesMutation,
} from "../../services/api";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { setStage } from "../../store/reducers/stageReducer";
import { toggleSubmission } from "../../store/reducers/tileReducer";

interface EquityModalProps {
  isOpen: boolean;
  onClose: () => void;
  setIsEventTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  isEventTrigger: boolean;
}

const CalendlyComponent: React.FC<EquityModalProps> = ({
  isOpen,
  onClose,
  setIsEventTrigger,
  isEventTrigger,
}) => {
  const dispatch = useAppDispatch();
  const stageData = useSelector((state: RootState) => state.stage);
  const [errors, setErrors] = useState<{ [key: string]: string | null }>({});
  const zohoCustomerId = useSelector(
    (state: RootState) => state.login.zohoCustomerId
  );
  const [updateStage] = useUpdateStagesMutation();
  const [eventId, setEventId] = useState<string | undefined>(undefined);
  const [evenId, setEvenId] = useState<string>("");
  const [updateMeeting] = useUpdateMeetingMutation();

  useEffect(() => {
    if (isOpen) {
      setErrors({});
    }
  }, [isOpen]);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      const uri = e.data.payload.event.uri;
      const eveId = uri.split("https://api.calendly.com/scheduled_events/")[1];

      setEventId(eveId);
      setEvenId(eveId);
    },
  });

  useEffect(() => {
    if (evenId !== "") {
      handleStage();
    }
  }, [evenId]);

  const updateEventInUser = async () => {
    try {
      const sendData = {
        zohoId: zohoCustomerId,
        eventId: evenId,
      };

      const result = await updateMeeting(sendData);
      console.log("updated event", result);
      setIsEventTrigger(!isEventTrigger);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleStage = async () => {
    console.log("trigger");
    if (stageData.cpaCallComplete && evenId) {
      console.log("update only when there is event id ");
      updateEventInUser();
      return;
    }
    console.log("iscallevent");

    ///
    await updateStage({
      zohoId: zohoCustomerId,
      key: "cpaCallComplete",
      eventId: evenId,
    });
    dispatch(
      setStage({
        ...stageData,
        cpaCallComplete: true,
      })
    );

    dispatch(toggleSubmission({ component: "tile2" }));
    setIsEventTrigger(!isEventTrigger);
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <h3 className={styles.modalTitle}>Schedule Call with Us</h3>
          <button className={styles.closeButton} onClick={onClose}>
            &times;
          </button>
        </div>
        <InlineWidget url="https://calendly.com/inception-financial/60min?month=2024-05" />
      </div>
    </div>
  );
};

export default CalendlyComponent;
