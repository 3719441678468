import React, { useEffect, useState } from "react";
import Tile1 from "./tile1";
import styles from "./index.module.css";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import Tile2 from "./tile2";
import Tile3 from "./tile3";
import Tile4 from "./tile4";
import Tile5 from "./tile5";
import Tile6 from "./tile6";
import Tile7 from "./tile7";
import { ReducerState, setSubmission } from "../../store/reducers/tileReducer";

interface IProps {
  apaData?: ApiResponse<IApaDoc>;
  refetchApa: any;
}

const DashboardTiles = ({ apaData, refetchApa }: IProps) => {
  const dispatch = useAppDispatch();
  const stageData = useSelector((state: RootState) => state.stage);
  const loginData = useSelector((state: RootState) => state.login);
  const { reducers: tiles } = useSelector((state: RootState) => state.tile);
  const lastName = loginData.lastName || "Test lastname";
  const zohoCustomerId = loginData.zohoCustomerId;

  const [overlayComponentData, setOverlayComponentData] = useState<any>(null);
  const [overlayUrl, setOverlayUrl] = useState("");
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [overlayVisible1, setOverlayVisible1] = useState(false);
  const [showAnimatedOverview, setShowAnimatedOverview] = useState(false);
  const [equityContributionLoader, setEquityContributionLoader] =
    useState(false);
  const [dashboardVideoUrl, setDashboardVideoUrl] = useState("");
  const [loader, setLoader] = useState(false);
  const [newTile, setNewTile] = useState<ReducerState[]>();

  const openOverlay = (url:string) => {
    setOverlayUrl(url);
    setOverlayVisible(true);
    setLoader(true);
  };
  const openAnimatedOverview = (url: string) => {
    setShowAnimatedOverview(true);
    setDashboardVideoUrl(url);
  };
  const openOverlay1 = (componentData: any) => {
    console.log(overlayComponentData,"--data")
    setOverlayComponentData(componentData);
    setOverlayVisible1(true);
  };
  const closeOverlay1 = () => {
    setOverlayComponentData(null);
    setOverlayVisible1(false);
  };
  const closeOverlay = () => {
    setOverlayVisible(false);
    setOverlayUrl("");
  };
  const sortTiles = () => {
    const tilesCopy = [...tiles];
    return tilesCopy.sort((a, b) => {
      if (a.isActive !== b.isActive) {
        return a.isActive ? -1 : 1;
      }
      if (a.isSubmitted !== b.isSubmitted) {
        return a.isSubmitted ? 1 : -1;
      }
      return a.isSubmitted ? a.order - b.order : b.order - a.order;
    });
  };

  useEffect(() => {
    setNewTile(sortTiles());
  }, [tiles]);

  useEffect(() => {
    if (
      (apaData?.data.CAF_Signed &&
        apaData?.data.MNDA_Signed &&
        apaData?.data.OMA_Signed) ||
      (!apaData?.data.CAF_Signed &&
        !apaData?.data.MNDA_Signed &&
        !apaData?.data.OMA_Signed &&
        !apaData?.data.request_id)
    ) {
      dispatch(setSubmission({ component: "tile5", isSubmitted: false }));
    } else {
      dispatch(setSubmission({ component: "tile5", isSubmitted: true }));
    }
    if (
      (apaData?.data.CAF_Signed &&
        apaData?.data.MNDA_Signed &&
        apaData?.data.OMA_Signed) ||
      (apaData?.data.CAF_Signed && !apaData?.data.mnda_request_id)
    ) {
      dispatch(setSubmission({ component: "tile6", isSubmitted: true }));
    } else {
      dispatch(setSubmission({ component: "tile6", isSubmitted: false }));
    }
  }, [apaData]);

  // console.log("new tile", newTile);

  return (
    <div className="my-8 mx-2 lg:mx-12">
      <div className="grid gap-x-8 gap-y-4 grid-cols-1 md:grid-cols-1 xl:grid-cols-2">
        {/* // learn more
// cpa call 2
// pro forma
// EquityContribution
// zoho document sign 5
// invite others
// my documents 7 */}
        {newTile?.map((tile) => {
          if (tile.component == "tile1")
            return (
              <Tile1
                openOverlay={openOverlay}
                openAnimatedOverview={openAnimatedOverview}
                openOverlay1={openOverlay1}
              />
            );
          else if (tile.component == "tile2")
            return <Tile2 stageData={stageData} />;
          else if (tile.component == "tile3")
            return <Tile3 openOverlay1={openOverlay1} lastName={lastName} />;
          else if (tile.component == "tile5")
            return (
              stageData.cpaCallComplete && (
                <Tile4
                  isLoading={false}
                  equityContributionLoader={equityContributionLoader}
                  setEquityContributionLoader={setEquityContributionLoader}
                  apaData={apaData?.data}
                  refetchApa={refetchApa}
                />
              )
            );
          else if (tile.component == "tile6")
            return (
              (equityContributionLoader || apaData?.data?.request_id) && (
                <Tile5
                  equityContributionLoader={equityContributionLoader}
                  zohoCustomerId={zohoCustomerId}
                  apaData={apaData?.data}
                />
              )
            );
          else if (tile.component == "tile7")
            return (
              stageData.softCommitComplete && (
                <Tile7 stageData={stageData} openOverlay1={openOverlay1} />
              )
            );
          else if (tile.component == "tile4") return <Tile6 />;
        })}
      </div>
      {overlayVisible1 && (
        <div className={styles.overlay} onClick={closeOverlay1}>
          <div
            className={styles.overlayContent}
            onClick={(e) => e.stopPropagation()}
          >
            {overlayComponentData}
            <span className={styles.closeBtn} onClick={closeOverlay1}>
              &times;
            </span>
          </div>
        </div>
      )}
      {showAnimatedOverview && (
        <div
          className={styles.overlay}
          onClick={() => setShowAnimatedOverview(false)}
        >
          <div
            className={styles.overlayContent}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="h-full w-full flex items-center justify-center relative mb-10">
              <ReactPlayer
                url={dashboardVideoUrl}
                controls={true}
                width="100%"
                height="100%"
                style={{ position: "relative" }}
              />
            </div>
            <span
              className={styles.closeBtn}
              onClick={() => setShowAnimatedOverview(false)}
            >
              &times;
            </span>
          </div>
        </div>
      )}
      {overlayVisible && (
        <div className={styles.overlay} onClick={closeOverlay}>
          <div
            className={styles.overlayContent}
            onClick={(e) => e.stopPropagation()}
          >
            {loader && (
              <div className="absolute inset-0 flex items-center justify-center">
                <svg
                  aria-hidden="true"
                  className="w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#50b56e]"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            )}
            <iframe
              src={overlayUrl}
              frameBorder="0"
              className={styles.overlayIframe}
              onLoad={() => setLoader(false)}
            ></iframe>
            <span className={styles.closeBtn} onClick={closeOverlay}>
              &times;
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardTiles;
