import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../configs/index";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.REACT_APP_BASE_URL}/api/user`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    verifyAccount: builder.mutation<VerifyResponse, string>({
      query: (payload) => ({
        url: `verify-account`,
        method: "POST",
        body: { zohoId: payload },
      }),
    }),
    verifyPin: builder.mutation<Response<Pin>, Login>({
      query: (body) => ({
        url: `verify-pin`,
        method: "POST",
        body: { zohoId: body.zohoId, zohoPin: body.pin },
      }),
    }),
    getStates: builder.query<StateResponse, void>({
      query: () => ({
        url: `states`,
      }),
    }),
    getStatesInfo: builder.query<StateInfoResponse, void>({
      query: () => ({
        url: `statesInfo`,
      }),
    }),
    submitForm: builder.mutation<Response<FormResponse>, Form>({
      query: (body) => ({
        url: `report-generate`,
        method: "POST",
        body: {
          stateId: body.stateId,
          estFederalTaxRate: body.taxRate,
          grossIncome: body.grossIncome,
        },
      }),
    }),
    reportForm: builder.mutation<Response<ReportResponse>, ReportForm>({
      query: (body) => ({
        url: `order`,
        method: "POST",
        body: {
          reportSummaryId: body.reportSummaryId,
          softCommitment: body.softCommitment,
          purchaseDate: body.datereadytopurchase,
        },
      }),
    }),
    getPastReports: builder.query<any, { page: number }>({
      query: ({ page }) => `/past-reports?page=${page}&limit=10`,
    }),
    setFavourite: builder.mutation<void, { reportSummaryId: string }>({
      query: ({ reportSummaryId }) => ({
        url: "/set-favourite",
        method: "POST",
        body: { reportSummaryId },
      }),
    }),
    rerunReport: builder.mutation<Response<ReportResponse>, ReportForm>({
      query: (body) => ({
        url: "/report-updation",
        method: "POST",
        body,
      }),
    }),
    changePin: builder.mutation<ChangePin, string>({
      query: (email) => ({
        url: `change-pin`,
        method: "POST",
        body: email,
      }),
    }),
    UpdateStages: builder.mutation<ChangePin, UpdateStage>({
      query: ({ zohoId, key, eventId }) => {
        console.log("Event ID:", eventId);
        return {
          url: `update-stages`,
          method: "PATCH",
          body: {
            zohoId,
            key,
            eventId,
          },
        };
      },
    }),
    getStages: builder.query<GetStages, void>({
      query: () => ({
        url: `get-stages`,
      }),
    }),
    entityInfo: builder.mutation<Response<any>, IEntityInfoReq>({
      query: (body) => ({
        url: `entity-info`,
        method: "POST",
        body,
      }),
    }),
    getFolderId: builder.query<ApiResponse<{ GDriveID: string }>, void>({
      query: () => ({
        url: `fetch-folderId`,
      }),
    }),
    submitEquityContribution: builder.mutation<
      void,
      { equityContribution: string }
    >({
      query: ({ equityContribution }) => ({
        url: "/equity-contribution",
        method: "POST",
        body: { equityContribution },
      }),
    }),
    getFoldersById: builder.mutation<
      any,
      { id: string; nextPageToken: string }
    >({
      query: ({ id, nextPageToken }) => ({
        url: `fetch-documents`,
        method: "POST",
        body: {
          id: id,
          nextPageToken: nextPageToken,
        },
      }),
    }),
    downloadFile: builder.mutation<any, any>({
      // not used
      query: (item) => ({
        url: `download-file`,
        method: "POST",
        body: item,
      }),
    }),
    getEventDetail: builder.query<ApiResponse<IEventDetail>, void>({
      query: () => ({
        url: `event`,
      }),
    }),
    getNdaValue: builder.query<ApiResponse<INdaForm>, void>({
      query: () => ({
        url: ``,
      }),
    }),
    equityContribution: builder.mutation<void, IEquityContribution>({
      query: (item) => ({
        url: `new-entity-setup`,
        method: "POST",
        body: item,
      }),
    }),
    sendLoginDetails: builder.mutation<ApiResponse<ISuccess>, IForgotPassword>({
      query: (item) => ({
        url: `get-login-details`,
        method: "POST",
        body: item,
      }),
    }),
    newClient: builder.mutation<ApiResponse<ISuccess>, INewClientReq>({
      query: (data) => ({
        url: `create-new-client`,
        method: "POST",
        body: data,
      }),
    }),
    entityLogin: builder.mutation<ApiResponse<VerifyId>, IEntityLoginReq>({
      query: (data) => ({
        url: `entity-login`,
        method: "POST",
        body: data,
      }),
    }),
    getEntitySetup: builder.query<ApiResponse<EntityInformation[]>, void>({
      query: () => ({
        url: `get-entity-setup`,
        method: "GET",
      }),
    }),
    addComment: builder.mutation<ApiResponse<void>, ICommentRequest>({
      query: (data) => ({
        url: `add-entity-comment`,
        method: "PATCH",
        body: data,
      }),
    }),
    uploadLicence: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: `upload-driving-licence`,
        method: "POST",
        body: formData,
      }),
    }),
    setEntityStatus: builder.mutation<void, ISetEntityStatusReq>({
      query: (data) => ({
        url: `set-entity-status`,
        method: "PATCH",
        body: data,
      }),
    }),
    referUser: builder.mutation<void, IReferData>({
      query: (data) => ({
        url: `refer-user`,
        method: "POST",
        body: data,
      }),
    }),
    getReferredUser: builder.query<ApiResponse<IReferCover>, { id: string }>({
      query: ({ id }) => ({
        url: `get-referred-user/${id}`,
        method: "GET",
      }),
    }),
    getConstant: builder.query<
      ApiResponse<{ federalBonusDepreciation: string }>,
      { type: string }
    >({
      query: ({ type }) => ({
        url: `constant?type=${type}`,
        method: "GET",
      }),
    }),
    getApaData: builder.query<ApiResponse<IApaDoc>, { id: string }>({
      query: ({ id }) => ({
        url: `get-apa/${id}`,
        method: "GET",
      }),
    }),
    downloadDriverId: builder.mutation<any, { fileId: string; zohoId: string }>(
      {
        query: ({ fileId, zohoId }) => ({
          url: `/download-driving-licence`,
          method: "POST",
          body: { fileId, zohoId },
          responseType: "blob",
        }),
      }
    ),
    getPurlFlow: builder.query<ApiResponse<IPURLFlow[]>, void>({
      query: () => ({
        url: `purl-flow`,
      }),
    }),
    updateMeeting: builder.mutation<ApiResponse<void>, IUpdateMeetingReq>({
      query: (data) => {
        return {
          url: `update-meeting`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    getZohoSignUrl: builder.query<ApiResponse<{ output: string }>, string>({
      query: (id) => ({
        url: `zoho-sign/${id}`,
      }),
    }),
    getEquityContributionStatus: builder.query<
      ApiResponse<{ exist: boolean }>,
      void
    >({
      query: () => ({
        url: `/new-entity-setup`,
      }),
    }),
    getCpa: builder.query<ApiResponse<IGetCpa>, void>({
      query: () => ({
        url: `/cpa`,
      }),
    }),
    updateCpa: builder.mutation<ApiResponse<{ code: string }>, IGetCpa>({
      query: (data) => {
        return {
          url: `/cpa`,
          method: "POST",
          body: data,
        };
      },
    }),
    // getUserImg: builder.query<Blob, string>({ //not used (failing to get image )
    //   query: (id) => ({
    //     url: `/user-img/${id}`,
    //     responseType: "buffer",
    //   }),
    // }),
    getContactOwner: builder.query<ApiResponse<IContactOwner>, string>({
      query: (id) => ({
        url: `/contact-owner/${id}`,
      }),
    }),
  }),
});

export const {
  useGetFolderIdQuery,
  useVerifyAccountMutation,
  useVerifyPinMutation,
  useGetStatesQuery,
  useGetStatesInfoQuery,
  useSubmitFormMutation,
  useReportFormMutation,
  useGetPastReportsQuery,
  useSetFavouriteMutation,
  useRerunReportMutation,
  useChangePinMutation,
  useUpdateStagesMutation,
  useGetStagesQuery,
  useEntityInfoMutation,
  useSubmitEquityContributionMutation,
  useGetFoldersByIdMutation,
  useDownloadFileMutation,
  useGetEventDetailQuery,
  useGetEntitySetupQuery,
  useGetNdaValueQuery,
  useEquityContributionMutation,
  useSendLoginDetailsMutation,
  useNewClientMutation,
  useEntityLoginMutation,
  useAddCommentMutation,
  useUploadLicenceMutation,
  useSetEntityStatusMutation,
  useGetReferredUserQuery,
  useGetConstantQuery,
  useGetApaDataQuery,
  useReferUserMutation,
  useDownloadDriverIdMutation,
  useGetPurlFlowQuery,
  useUpdateMeetingMutation,
  useLazyGetZohoSignUrlQuery,
  useGetEquityContributionStatusQuery,
  useGetCpaQuery,
  useUpdateCpaMutation,
  useGetContactOwnerQuery,
} = api;
