import React, { useState } from "react";
import styles from "./index.module.css";
import CalendlyComponent from "../CalendlyComponent";
import { setStage, UserState } from "../../store/reducers/stageReducer";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { toggleSubmission } from "../../store/reducers/tileReducer";
import { useUpdateStagesMutation } from "../../services/api";

interface Props {
  eventId: string;
  setIsEventTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  isEventTrigger: boolean;
  time: string;
  timeZone: string;
  stageData: UserState;
  isScheduleDatePasses: boolean;
  scheduledDate: ScheduledDate | undefined;
}

const Tile2 = ({
  eventId,
  setIsEventTrigger,
  stageData,
  time,
  timeZone,
  isEventTrigger,
  scheduledDate,
  isScheduleDatePasses,
}: Props) => {
  const zohoCustomerId = useAppSelector((state) => state.login.zohoCustomerId);
  const dispatch = useAppDispatch();
  const [isOpenCalendly, setIsOpenCalendly] = useState(false);
  const [updateStage] = useUpdateStagesMutation();

  const skipButton = async () => {
    //backend call for cpa call
    try {
      await updateStage({
        zohoId: zohoCustomerId,
        key: "cpaCallComplete",
        eventId: "skip", //do not change (using in backend)
      });
      dispatch(
        setStage({
          ...stageData,
          cpaCallComplete: true,
        })
      );
      dispatch(toggleSubmission({ component: "tile2" }));
      setIsEventTrigger(!isEventTrigger);
    } catch (err) {
      console.log(`err`, err);
    }
  };

  return (
    <div className={`${styles.tile1} ${styles.customTile}`}>
      <div className={styles.tile1Container1}>
        <h2 className={styles.heading} style={{ fontFamily: "Signika" }}>
          {stageData.cpaCallComplete && scheduledDate != undefined
            ? "Schedule a Call with Inception Financial"
            : "Next Step: Schedule a Call with CPA"}
        </h2>
        <div className={styles.tile2desp}>
          {isScheduleDatePasses && scheduledDate != undefined
            ? "Have additional questions? We are ready to meet with you! Schedule a call using the button below."
            : "It is time to schedule a call between you, the Inception Team and your CPA. Please choose the day and time that works best for you and your advisor."}
        </div>
      </div>
      <div className={styles.tile1Box}>
        <div
          className="mt-6 w-[80%] flex justify-center  flex-col items-center h-full"
          style={{ backgroundColor: "#fff", borderRadius: "5px" }}
        >
          {stageData.cpaCallComplete && !isScheduleDatePasses ? (
            <>
              {scheduledDate != undefined && eventId !== "skip" && (
                <div className="text-center ">
                  <span className="font-semibold">Your Call is Scheduled</span>
                  <div className="flex flex-col p-1 ">
                    <span className="p-2 rounded border">
                      Your Call is Scheduled for {scheduledDate?.start_date} at{" "}
                      {time} {timeZone}
                    </span>
                  </div>
                </div>
              )}

              <button
                onClick={() => setIsOpenCalendly(true)}
                className={styles.tile4Button}
              >
                SCHEDULE A CALL
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => setIsOpenCalendly(true)}
                className={styles.tile4Button}
              >
                SCHEDULE A CALL
              </button>
              {eventId !== "skip" && (
                <button
                  onClick={skipButton}
                  className="bg-[#356C58] text-white  rounded-sm text-[14px] mt-[5px] py-2 px-3 "
                >
                  SKIP THIS STEP
                </button>
              )}
            </>
          )}
          <CalendlyComponent
            setIsEventTrigger={setIsEventTrigger}
            isEventTrigger={isEventTrigger}
            isOpen={isOpenCalendly}
            onClose={() => setIsOpenCalendly(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default Tile2;
