import styles from "./index.module.css";
import img from "../../assets/images/InceptionFinicallyLogo.png";
import {
  useDownloadDriverIdMutation,
  useGetEntitySetupQuery,
} from "../../services/api";
import EntityInfoTable from "./EntityInfoTable";
import { useEffect, useState } from "react";
import Loader from "../Loader";
import { useForm } from "react-hook-form";
import { entityCommentSchema } from "../../yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InformationPopup from "./InformationPopup";
import CompletedPopup from "./CompletedPopup";
import { config } from "../../configs/index";

interface FormValues {
  comment: string;
}

const EntityInfo = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(entityCommentSchema),
  });
  const token = localStorage.getItem("token") || "";
  const { data, isLoading, refetch } = useGetEntitySetupQuery();
  const [downloadId] = useDownloadDriverIdMutation();

  // const [isRejectClicked, setIsRejectClicked] = useState(false);
  const [isCompletedClicked, setIsCompletedClicked] = useState(false);
  const [popup, setPopup] = useState(false);
  const [commentData, setCommentData] = useState("");
  const [popupData, setPopupData] = useState<EntityInformation>();
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [downloadIndex, setDownloadIndex] = useState(-1);

  const downloadAttachment = async (
    e: any,
    fileId: string,
    zohoId: string,
    index: number
  ) => {
    e.stopPropagation();
    setDownloadLoader(true);
    setDownloadIndex(index);
    try {
      if (!fileId) {
        console.log("No ID found");
        return;
      }
      const data = {
        fileId: fileId,
        zohoId: zohoId,
      };
      const response = await fetch(
        `${config.REACT_APP_BASE_URL}/api/user/download-driving-licence`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch the file");
      }

      const contentDisposition = response.headers.get("Content-Disposition");
      const contentType =
        response.headers.get("Content-Type") || "application/octet-stream";

      let filename = "Driver_Licence";
      if (contentDisposition && contentDisposition.includes("filename=")) {
        const filenameMatch = contentDisposition.match(/filename="([^"]*)"/);
        if (filenameMatch && filenameMatch[1]) {
          filename = filenameMatch[1];
        }
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download error:", error);
    } finally {
      setDownloadLoader(false);
    }
  };

  // const onSubmit: SubmitHandler<FormValues> = async (values) => {
  //   try {
  //     const id = popupData?._id || "";
  //     const data = {
  //       comment: values.comment,
  //       id: id,
  //     };

  //     const result = await addComment(data).unwrap();
  //     if (result.success) {
  //       setIsRejectClicked(false);
  //       showNotification("success", "Notes sent successfully");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const addCommentFunc = (e: any) => {
  //   const data = e.target.value;
  //   setCommentData(data);
  // };

  useEffect(() => {
    // if (popupData?.message) {
    //   setCommentData(popupData.message);
    //   setValue("comment", popupData.message);
    // }
    if (
      (data?.data ?? []).length > 0 &&
      isCompletedClicked == false /*|| isRejectClicked == false*/
    ) {
      setTimeout(() => {
        refetch();
      }, 1000);
    }
  }, [isCompletedClicked /*isRejectClicked*/]);

  return (
    <>
      <div className={`${styles.container}`}>
        <div className=" flex justify-start md:px-10 pb-20 absolute top-0 left-0">
          <a href="https://www.inception.financial/" target="_blank">
            <img src={img} alt="" className={`${styles.image}`} />
          </a>
        </div>
        <div className="m-auto w-[98vw] md:w-[80vw] h-[70vh]">
          <div className=" text-white overflow-auto h-full bg-white rounded-md py-2 md:px-4">
            {!isLoading && (
              <EntityInfoTable
                setPopup={setPopup}
                setPopupData={setPopupData}
                data={data?.data || []}
                downloadLoader={downloadLoader}
                downloadIndex={downloadIndex}
                downloadAttachment={downloadAttachment}
              />
            )}
            {isLoading && <Loader isLoading={isLoading} size={48} />}
          </div>
        </div>
      </div>
      {popup && (
        <InformationPopup
          setPopup={setPopup}
          // setIsRejectClicked={setIsRejectClicked}
          setIsCompletedClicked={setIsCompletedClicked}
          data={popupData}
          downloadLoader={downloadLoader}
          downloadAttachment={downloadAttachment}
          refetch={refetch}
        />
      )}
      {/* {isRejectClicked && (
        <div className={styles.popupContainer}>
          <div className={styles.popup}>
            <p className="text-black w-full">
              <IoClose
                onClick={() => setIsRejectClicked(false)}
                className="ml-auto text-xl cursor-pointer"
              />
            </p>
            <div className="w-full h-full">
              <p className="p-2 pt-0 text-black">Add your comment below</p>
              <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                <textarea
                  {...register("comment")}
                  rows={8}
                  onChange={addCommentFunc}
                  value={commentData}
                  className="w-full border-2 border-black rounded-lg p-2 text-black"
                />
                {errors.comment && (
                  <div className="text-red-500">{errors.comment.message}</div>
                )}
                <CustomButton buttonLabel="SUBMIT" isLoading={commentLoading} />
              </form>
            </div>
          </div>
        </div>
      )} */}
      {isCompletedClicked && (
        <CompletedPopup
          setIsCompletedClicked={setIsCompletedClicked}
          zohoId={popupData?.zohoCustomerId || ""}
        />
      )}
    </>
  );
};

export default EntityInfo;
