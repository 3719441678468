const config: AppConfig = {
  REACT_APP_BASE_URL: "https://api.inceptionfinancial.app",
  REACT_APP_NODE_ENV: "production",
  REACT_APP_CLIENT_PORTAL_URL: "https://client.inceptionfinancial.app",
  ZD_CSRF_TOKEN: "a54f757b-c934-4376-9874-2f8c2886059b",
  REACT_APP_MAIN_SPREAD_SHEET_ID:
    "1ZS9eMf_mnY4S7VOqafETOUxV8kDHlhEGjw_e1nkrKWA",
  REACT_APP_SHARED_FOLDER_ID: "1YThLt3Xev1eQWqaQyFQUqrdLUvOPavEE",
  REACT_APP_MASTER_FILE_ID: "1OO2weqviEgcv9kA39yGIBrmHQkymaHgNrKypb1ml1bM",
  REACT_APP_AMP_VANTAGE_FOLDER_ID: "1X49b1wI1OXEqOntiWg_9RyxQoPwWlMmb",
  REACT_APP_AMP_VANTAGE_PDF_FOLDER_ID: "1a7ED8HbX5xIj2liwy_wK4QhllleqFTEt",
  REACT_APP_SLIDE_ID: "1gp1Kr3yZ20ScAO5NK9oBHfa_UPD8nsk5rbDNH7Ldjec",
};
export default config;
