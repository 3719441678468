import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import styles from "./index.module.css";
// import { string, Schema } from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Loader";
import { useEntityInfoMutation, useGetStatesQuery } from "../../services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { EntitySchema } from "../../yup";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import CustomInput from "../CustomInput";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { setStage } from "../../store/reducers/stageReducer";
import CustomButton from "../Button/CustomButton";

interface EquityModalProps {
  isOpen: boolean;
  onClose: () => void;
  setShowEquityChart: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleModal: () => void;
  equityFormLoader: boolean;
  setEquityFormLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

const EquityModal: React.FC<EquityModalProps> = ({
  isOpen,
  onClose,
  setShowEquityChart,
  toggleModal,
  setIsOpen,
  equityFormLoader,
  setEquityFormLoader,
}) => {
  
  const [stateOptions, setStateOptions] = useState<StateOption[]>();
  const { data ,isLoading} = useGetStatesQuery();
  interface FormData {
    entityName: string;
    entityType: { value: string; label: string };
    stateOfIncorporation: { value: string; label: string };
    businessTitle: string;
    mailingStreet: string;
    mailingCity: string;
    mailingCountry: string;
    mailingState: { value: string; label: string };
    mailingZip: string;
    [key: string]: any;
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormData>({ resolver: yupResolver(EntitySchema) });

  const getStates = () => {
    const states = data?.data || [];
    const stateOption: StateOption[] = states?.map((item) => {
      return {
        label: item.state,
        value: item._id,
      };
    });
    setStateOptions(stateOption);
  };
  useEffect(() => {
    if (data) {
      getStates();
    }
  }, [data]);
  const dispatch = useAppDispatch();
  const loginData = useSelector((state: RootState) => state.login);
  const stageData = useSelector((state: RootState) => state.stage);
  const [equityResult, { isLoading: equityResultLoading }] =
    useEntityInfoMutation();
  const onSubmit = async (values: FormData) => {
    try {
      
      setEquityFormLoader(true);
      const formData = {
        entityName: values.entityName,
        entityType: values.entityType.value,
        stateOfIncorporation: values.stateOfIncorporation.value,
        businessTitle: values.businessTitle,
        mailingStreet: values.mailingStreet,
        mailingSuite: values.mailingSuite,
        mailingCity: values.mailingCity,
        mailingCountry: "United States",
        mailingState: values.mailingState.value,
        mailingZip: values.mailingZip,
        isEmail: false,
      };
      const data = (await equityResult({
        ...formData,
        zohoCustomerId: loginData.zohoCustomerId,
      })) as Response<any>;
      if (data.data?.success) {
        dispatch(
          setStage({
            ...stageData,
            entityInformationCollected: true,
          })
        );
        setShowEquityChart(true);
      }
      setIsOpen(false);
    } catch (err) {
      console.log(err);
    } finally {
      setEquityFormLoader(false);
    }
  };
  const options = [
    { label: "LLC", value: "LLC" },
    { label: "Individual", value: "Individual" },
    { label: "S-Corp", value: "S-Corp" },
    { label: "C-Corp", value: "C-Corp" },
    { label: "Profit Corporation", value: "Profit Corporation" },
    { label: "Other", value: "Other" },
    { label: "Not Applicable", value: "Not Applicable" },
  ];
  if (!isOpen) return null;
  return (
    <>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          {equityFormLoader ? (
            <Loader  isLoading={true} />
          ) : (
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">
                  Entity Name
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className={styles.infoIcon}
                  />
                  <div className={styles.tooltip}>
                    Please enter full legal name for your entity as it would do
                    business
                  </div>
                </div>
                <div className="w-full">
                  <CustomInput
                    name="entityName"
                    register={register}
                    error={errors.entityName}
                    type="text"
                    placeholder="Enter entity name"
                  />
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">Entity Type*</div>
                <div className="w-full">
                  <Controller
                    name="entityType"
                    control={control}
                    render={({ field }: any) => (
                      <Select
                        {...field}
                        placeholder="Select entity type"
                        options={options}
                        onChange={(selectedOption: StateOption) =>
                          field.onChange(selectedOption)
                        }
                      />
                    )}
                  />
                  {errors.entityType?.message && (
                    <p className="text-red-500 text-[14px] text-left font-semibold">
                      {errors.entityType?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">State of Incorporation*</div>
                <div className="w-full">
                  <Controller
                    name="stateOfIncorporation"
                    control={control}
                    render={({ field }: any) => (
                      <Select
                        {...field}
                        placeholder="Select state of incorporation"
                        isLoading={isLoading}
                        options={stateOptions}
                        onChange={(selectedOption: StateOption) =>
                          field.onChange(selectedOption)
                        }
                      />
                    )}
                  />
                  {errors.stateOfIncorporation?.message && (
                    <p className="text-red-500 text-[14px] text-left font-semibold">
                      {errors.stateOfIncorporation?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">
                  Business Title
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className={styles.infoIcon}
                  />
                  <div className={styles.tooltip}>
                    What is your title in the business? Common entries are
                    Owner, Managing Member and CEO
                  </div>
                </div>
                <div className="w-full">
                  <CustomInput
                    name="businessTitle"
                    register={register}
                    error={errors.businessTitle}
                    type="text"
                    placeholder="Enter business title"
                  />
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">Mailing Street*</div>
                <div className="w-full">
                  <CustomInput
                    name="mailingStreet"
                    register={register}
                    error={errors.mailingStreet}
                    type="text"
                    placeholder="Enter mailing street"
                  />
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">Mailing Suite</div>
                <div className="w-full">
                  <CustomInput
                    name="mailingSuite"
                    register={register}
                    type="text"
                    placeholder="Enter mailing suite"
                  />
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">Mailing Country*</div>
                <div className="w-full pointer-events-none">
                  <CustomInput
                    name="mailingCountry"
                    register={register}
                    type="text"
                    defaultValue="United States"
                    placeholder="Enter mailing country"
                  />
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">Mailing State*</div>
                <div className="w-full">
                  <Controller
                    name="mailingState"
                    control={control}
                    render={({ field }: any) => (
                      <Select
                        {...field}
                        placeholder="Select State"
                        isLoading={isLoading}
                        options={stateOptions}
                        onChange={(selectedOption: StateOption) =>
                          field.onChange(selectedOption)
                        }
                      />
                    )}
                  />
                  {errors.mailingState?.message && (
                    <p className="text-red-500 text-[14px] text-left font-semibold">
                      {errors.mailingState?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">Mailing City*</div>
                <div className="w-full">
                  <CustomInput
                    name="mailingCity"
                    register={register}
                    error={errors.mailingCity}
                    type="text"
                    placeholder="Enter mailing city"
                  />
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">Mailing Zip*</div>
                <div className="w-full">
                  <CustomInput
                    name="mailingZip"
                    register={register}
                    error={errors.mailingZip}
                    type="text"
                    placeholder="Enter mailing zip"
                  />
                </div>
              </div>
              <div className="flex justify-end mt-2">
                <button type="submit" className={styles.tileButton}>
                  SUBMIT
                </button>
               
                <div className="flex justify-end"></div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default EquityModal;
