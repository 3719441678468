import TaxSheet from "../../assets/images/TaxSheet.png";
import Button from "../Button";
import styles from "./index.module.css";
import Table from "../Table";
import { useState } from "react";
import { backStep, nextStep } from "../../store/reducers/stepReducer";
import { useAppDispatch } from "../../store/store";
import { selectFormData } from "../../store/reducers/stageReducer";
import { config } from "../../configs/index";
import { useSelector } from "react-redux";
import TableTop from "../TableTop";
import moment from "moment";
import { useGetConstantQuery } from "../../services/api";
type TableTypes = "6 Year Proforma" | "20 Year Proforma";

const Step3_1 = () => {
  const lastName = useSelector((state: any) => state.login.lastName);
  const formData = useSelector(selectFormData);
  const token = localStorage.getItem("token") || "";
  const table = formData;
  const dispatch = useAppDispatch();
  const { data: constantData } = useGetConstantQuery({ type: "Primary Model" });

  const row = Array.from({ length: 87 }, (_, index) => index + 1);
  const [tableType, setTableType] = useState<TableTypes>("6 Year Proforma");
  const addFields = (
    data: any[],
    fields: any[],
    slice: boolean,
    sliceNum?: number
  ) => {
    return data?.map((d, index) => {
      if (slice) {
        return [fields[index], ...d?.slice(0, sliceNum ? sliceNum : 7)];
      } else {
        return [fields[index], ...d];
      }
    });
  };

  const getExcel = async (row: any) => {
    try {
      const response = await fetch(
        `${config.REACT_APP_BASE_URL}/api/user/download-report`,
        {
          method: "POST",
          body: JSON.stringify({ id: row?._id }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response || !response.body) {
        console.log("Something Went Wrong");
        return;
      }
      const contentLength = +response.headers.get("content-length");
      const reader = response.body.getReader();
      let receivedLength = 0;
      const chunks = [];
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks.push(value);
        receivedLength += value.length;
      }

      const newDate = moment(row?.createdAt).format("MM.DD.YYYY");
      console.log(row, "rororororo");
      const fileName =
        lastName + "-" + row?.equalityContribution + "-" + newDate;

      const blob = new Blob(chunks);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.xlsx`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("Error downloading Excel file");
    }
  };

  const downloadPackage = () => {
    const row = table;
    getExcel(row);
  };

  const goBack = () => {
    dispatch(backStep());
  };

  const [year, setYear] = useState(0);

  const benefits = {
    headers: ["Benefits Summary"],
    fields: [
      "Equity Contribution",
      "Net Benefit",
      "Net Benefit Percentage",
      "Operating Exp",
      "Total Benefit",
    ],
    data: [
      [`$ ${table?.summary[year].equalityContribution.toLocaleString()}`],
      [`$ ${table?.summary[year].netBenefit.toLocaleString()}`],
      [`${table?.summary[year].netBenefitPercentage}%`],
      [`$ ${table?.summary[year].operatingExp.toLocaleString()}`],
      [`$ ${table?.summary[year].totalBenefit.toLocaleString()}`],
    ],
  };

  const investorYear: any = {
    headers: ["Investor Return", "Year"],
    fields: ["", "Annual Benefit"],
    data: [Array.from({ length: 20 }, (_, index) => index + 1), []],
  };
  table?.investorReturn.forEach((data: any, index: any) => {
    investorYear.data[1][index] = data.annualBenefit;
  });

  const fieldsToExtract = [
    "assetPurchasePrice",
    "prepaymentIncomeFederal",
    "adminFeeFromPropertyHost",
    "equalityContribution",
    "operatingExp",
    "federalDeprecitaionNOL",
    "federalTaxCredit",
    "prepaymentIncomeState",
    "stateDepreciationNOL",
    "netBenefit",
  ];
  const fieldValues = fieldsToExtract.map((field) => {
    return table?.investorReturn?.map((obj: any) => obj[field]) || [];
  });

  const macroYear = {
    headers: ["MACROECONOMICS"],
    fields: [
      "Asset Purchase Price",
      "Prepayment Income (Federal)",
      "Admin Fee from Property Host",
      "Equity Contribution",
      "Operating Expenses",
      "Federal Deprecitaion/NOL",
      "Federal Tax Credit",
      "Prepayment Income (State)",
      "State Depreciation/NOL",
      "Net Benefit",
    ],
    data: fieldValues,
  };

  const incomeTaxFieldsToExtract = [
    "year",
    "",
    "prepaymentIncomeFederal",
    "adminFeeFromPropertyHost",
    "totalFederalRevenue",
    "",
    "operatingExp",
    "operatingExp",
    "ebitda",
    "",
    "bonus80Percent",
    "macrsDepRate",
    "federalDeprecitaionNOL",
    "nolCarryForward",
    "ebit",
    "incomeBeforeIncomeTax",
    "",
    "",
    "prepaymentIncomeState",
    "operatingExp",
    "macrsDepRate",
    // "prepaymentIncomeState",
    "stateDepreciationNOL",
    "stateAssetsSale",
    "",
    "",
    "totalStateIncome",
    "stateIncomeTaxRate",
    "stateIncomeTaxSavingPayment",
    "",
    "statePteTaxPercent",
    "statePteTaxPayment",
    "stateIncomeAfterPtePayment",
    "federalIncomeTaxRate",
    "federalIncomeTaxSaving",
    "",
    "totalFedAndIncomeTaxSaving",
    "federalTaxCredit",
    "annualBenefit",
  ];

  const incomeTaxFieldValues = incomeTaxFieldsToExtract.map((field) => {
    const intermidiate = table?.investorReturn
      ?.filter((obj: any) => !(obj.year === 6 && obj.is20Year === false))
      .sort((a: any, b: any) => a.year - b.year);
    return intermidiate.map((obj: any) => obj[field]) || [];
  });

  const bonusPercent = constantData?.data.federalBonusDepreciation || 80;

  const incomeTaxField = {
    headers: ["INCOME TAX STATEMENT", "Year"],
    fields: [
      "",
      "REVENUE",
      "Prepayment from Property Host",
      "Admin Fee from Property Host",
      "Total Federal Revenue",
      "Operating Expenses",
      "Asset Management",
      "Total Opex",
      "EBITDA",
      "FEDERAL DEPRECATION",
      `Bonus (${bonusPercent}%)`,
      "MACRS Depreciation Rate",
      // "Remaining MACRS Depreciation",
      "Depreciation/NOL Used",
      "NOL Carry-Forward",
      "EBIT",
      "INCOME BEFORE INCOME TAX",
      "TAX CREDITS AND INCOME TAX",
      "State",
      "State Revenue - from Prepayment",
      "Total Operating Expenses",
      "Depreciation rate",
      "State Depreciation",
      "State Asset Sale",
      "State Carryover Loss for Non Sales Tax State",
      "Federal Depreciation Basis Reduction Add Back",
      "Total State Income",
      "State Income tax rate",
      "State Income Tax Savings (Payments)*",
      "Federal",
      "State PTE Tax %",
      "State PTE Tax Payment",
      "Federal income after PTE Payment",
      "Federal income tax rate",
      "Federal Income Tax Savings (Payments)*",
      "State & Federal",
      "Total Fed & State Income Tax Savings (Payments)*",
      "Federal Tax Credit",
      "Total Income Tax Savings (Payments)*",
    ],
    data: incomeTaxFieldValues,
  };

  const taxAndCashStatementFieldsToExtract = [
    "year",
    "totalNetCF_PRETAX",
    "",
    "totalStateIncome",
    "stateIncomeTaxSavingPayment",
    "stateIncomeAfterPtePayment",
    "federalIncomeTaxSaving",
    "totalFedAndIncomeTaxSaving",
    "federalTaxCredit",
    "totalNetIncomeTaxSavingPayment",
    "annualBenefit",
  ];

  const taxAndCashStatementFieldValues = taxAndCashStatementFieldsToExtract.map(
    (field) => {
      return table?.investorReturn?.map((obj: any) => obj[field]) || [];
    }
  );

  const taxAndCashStatement = {
    headers: ["TAX & CASH STATEMENT", "Year"],
    fields: [
      "",
      "TOTAL NET CF (PRETAX)",
      "INCOME TAX",
      "State income",
      "State Income Tax Savings (Payments)*",
      "Federal income",
      "Fed Income Tax Savings (Payments)*",
      "Total Income Tax Savings (Payments)*",
      "Federal Tax Credit",
      "Total Net Income Tax Savings (Payments)*",
      "TOTAL NET TAX & CASH BENEFIT",
    ],
    data: taxAndCashStatementFieldValues,
  };

  const yr6DataFor20yrs = table?.investorReturn.find((e: any) => {
    return e.year == 6 && e.is20Year == true;
  });

  if (tableType === "6 Year Proforma") {
    investorYear.data[0].splice(6, 0, "6 Yr Total");
    investorYear.data[1].splice(
      6,
      0,
      table?.yearsTotal[year].annualBenefit as number
    );
    macroYear.data[0].splice(
      6,
      0,
      table?.yearsTotal[year].assetPurchasePrice as number
    );
    macroYear.data[1].splice(
      6,
      0,
      table?.yearsTotal[year].prepaymentIncomeFederal as number
    );
    macroYear.data[2].splice(
      6,
      0,
      table?.yearsTotal[year].adminFeeFromPropertyHost as number
    );
    macroYear.data[3].splice(
      6,
      0,
      table?.yearsTotal[year].equalityContribution as number
    );
    macroYear.data[4].splice(
      6,
      0,
      table?.yearsTotal[year].operatingExp as number
    );
    macroYear.data[5].splice(
      6,
      0,
      table?.yearsTotal[year].federalDeprecitaionNOL as number
    );
    macroYear.data[6].splice(
      6,
      0,
      table?.yearsTotal[year].federalTaxCredit as number
    );
    macroYear.data[7].splice(
      6,
      0,
      table?.yearsTotal[year].prepaymentIncomeState as number
    );
    macroYear.data[8].splice(
      6,
      0,
      table?.yearsTotal[year].stateDepreciationNOL as number
    );
    macroYear.data[9].splice(
      6,
      0,
      table?.yearsTotal[year].netBenefit as number
    );
  } else {
    investorYear.data[1][5] = yr6DataFor20yrs?.annualBenefit;
    investorYear.data[0].splice(6, 1, 7);
    investorYear.data[0].splice(20, 0, "20 Yr Total");
    investorYear.data[1].splice(
      20,
      0,
      table?.yearsTotal[year].annualBenefit as number
    );
    macroYear.data[0].splice(
      20,
      0,
      table?.yearsTotal[year].assetPurchasePrice as number
    );
    macroYear.data[1].splice(
      20,
      0,
      table?.yearsTotal[year].prepaymentIncomeFederal as number
    );
    macroYear.data[2].splice(
      20,
      0,
      table?.yearsTotal[year].adminFeeFromPropertyHost as number
    );
    macroYear.data[3].splice(
      20,
      0,
      table?.yearsTotal[year].equalityContribution as number
    );
    macroYear.data[4].splice(
      20,
      0,
      table?.yearsTotal[year].operatingExp as number
    );
    macroYear.data[5].splice(
      20,
      0,
      table?.yearsTotal[year].federalDeprecitaionNOL as number
    );
    macroYear.data[6].splice(
      20,
      0,
      table?.yearsTotal[year].federalTaxCredit as number
    );
    macroYear.data[7].splice(
      20,
      0,
      table?.yearsTotal[year].prepaymentIncomeState as number
    );
    macroYear.data[8].splice(
      20,
      0,
      table?.yearsTotal[year].stateDepreciationNOL as number
    );
    macroYear.data[9].splice(
      20,
      0,
      table?.yearsTotal[year].netBenefit as number
    );
  }

  investorYear?.data[1].pop();
  macroYear.data.map((e) => e.pop());
  // incomeTaxFieldValues

  return (
    <div
      className="mt-8 "
      style={{
        height: "90%",
        width: "100%",
        overflowX: "auto",
        overflowY: "auto",
      }}
    >
      <div className={styles.btnResponsive}>
        <div>
          <Button
            title="6 Years"
            onClick={() => {
              setTableType("6 Year Proforma");
              setYear(0);
            }}
            variant={tableType === "6 Year Proforma" ? "light" : "light1"}
            className="rounded-full rounded-e-none"
          />
          <Button
            title="20 Years"
            onClick={() => {
              setTableType("20 Year Proforma");
              setYear(1);
            }}
            variant={tableType === "20 Year Proforma" ? "light" : "light1"}
            className="rounded-full rounded-s-none"
          />
        </div>
        <div className={styles.backBtn}>
          <Button
            className={styles.btnTop2}
            title="Download"
            onClick={downloadPackage}
          />
        </div>
      </div>

      <div className="mb-8">
        <TableTop<any>
          headerFields={benefits.headers}
          fields={benefits.fields}
          data={addFields(benefits.data, benefits.fields, false)}
          name="w-50 min-w-58 sm:min-w-60 sm:w-60 "
        />
      </div>
      {tableType === "6 Year Proforma" && (
        <div>
          <Table<any>
            className="overflow-x-hidden mt-2"
            headerFields={investorYear.headers}
            fields={investorYear.fields}
            data={addFields(investorYear.data, investorYear.fields, true)}
            name="w-[100px] min-w-[250px]"
          />
          <Table<any>
            className="overflow-x-hidden mt-8"
            headerFields={macroYear.headers}
            fields={macroYear.fields}
            data={addFields(macroYear.data, macroYear.fields, true)}
            name="w-[100px] min-w-[250px]"
          />
          <Table<any>
            className="overflow-x-hidden mt-8"
            headerFields={incomeTaxField.headers}
            fields={incomeTaxField.fields}
            data={addFields(
              incomeTaxField.data,
              incomeTaxField.fields,
              true,
              6
            )}
            name="w-[100px] min-w-[250px]"
          />
        </div>
      )}
      {tableType === "20 Year Proforma" && (
        <div>
          <Table<any>
            className="overflow-x-hidden mt-8"
            headerFields={investorYear.headers}
            fields={investorYear.fields}
            name="w-[100px] min-w-[250px]"
            data={addFields(investorYear.data, investorYear.fields, false)}
          />
          {/* <Table<any>
              className="overflow-x-hidden"
              headerFields={macroYear.headers}
              fields={macroYear.fields}
              name="w-[100px] min-w-[250px]"
              data={addFields(macroYear.data, macroYear.fields, false)}
            /> */}

          <Table<any>
            className="mt-8 overflow-x-hidden"
            headerFields={incomeTaxField.headers}
            fields={incomeTaxField.fields}
            data={addFields(
              incomeTaxField.data,
              incomeTaxField.fields,
              true,
              20
            )}
            name="w-[100px] min-w-[250px]"
          />
          <Table<any>
            className="mt-8 overflow-x-hidden"
            headerFields={taxAndCashStatement.headers}
            fields={taxAndCashStatement.fields}
            data={addFields(
              taxAndCashStatement.data,
              taxAndCashStatement.fields,
              true,
              20
            )}
            name="w-[100px] min-w-[250px]"
          />
        </div>
      )}
      <div>
        <p className={styles.disText}>
          * Positive amounts reflect tax savings, negative amounts reflect tax
          payments
        </p>
        <p className={styles.disText}>
          ** 75% cap (1M liability - 750K max ITCs for current year)
        </p>
        <p className={styles.disText}>
          *** If you want to spread out income, you must elect your LLC to use
          Accrual method
        </p>
        <br />
        <h1 className={styles.disTextHead}>Disclaimer</h1>
        <p className={styles.disText}>
          This is not a solicitation or offer to sell securities and should not
          be construed as such, and no facts relating to actual investment are
          presented. Discussion of U.S. Federal and State Tax Implications of
          such investments is meant to be general in nature and does not discuss
          all aspects of U.S. Federal and State Income Taxation that may be
          relevant to a prospective Investor. None of this content may be
          construed as providing tax advice.
        </p>
        <p className={styles.disText}>
          Depreciation calculations assume solar assets are placed in service in
          Q1, applying the full MACRS formula for the calendar year. Variations
          in timing or methods may result in different outcomes. Please consult
          a tax professional for personalized advice.
        </p>
        <br />
        <h1 className={styles.disTextHead}>Statement of Confidentiality</h1>
        <p className={styles.disText}>
          The information in this document has been prepared by INCEPTION
          FINANCIAL LLC, and any of its affiliates, including but not limited
          to, Inception Financial Development, LLC. This document is private and
          confidential and contains proprietary information. This document is
          not for public distribution. INCEPTION FINANCIAL LLC provides this
          document with the understanding that its contents will be held in
          strict confidence, and will not be duplicated or used in whole or part
          for any purpose other than evaluation of this document.
        </p>
      </div>
      <div className="flex mt-6 justify-end">
        {/* <Button
          className={styles.btn1}
          title="Back"
          type="submit"
          onClick={goBack}
          variant="back"
        /> */}
        <Button
          className={styles.btn2}
          title="Download"
          onClick={downloadPackage}
        />
      </div>
    </div>
  );
};

export default Step3_1;
