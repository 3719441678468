import styles from "./index.module.css";
import { EPCProject } from "./EpcTable";
import { useState } from "react";
import Loader from "../../Loader";
import { Popup } from "./Popup";
import { Dots } from "./Popup/Dots";
import { IoClose } from "react-icons/io5";
import { useUpdateStatusMutation } from "../../../services/epcApi";
import { showNotification } from "../../../services/showNotification";

export interface IColor {
  one: string;
  two: string;
  three: string;
  four: string;
  five: string;
  six: string;
  seven: string;
  eight: string;
}

export const EpcPopUp = ({
  data,
  setPopup,
}: {
  data: EPCProject | undefined;
  setPopup: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [updateStatus, { isLoading: submitting }] = useUpdateStatusMutation();

  const [gemsPopup, setGemsPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [color, setColor] = useState<IColor>({
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
    seven: "",
    eight: "",
  });
  const buttonArray: (keyof IColor)[] = [
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
  ];

  const checkColor = () => {
    const colorValues = Object.values(color);
    const greens = colorValues.filter((item) => item == "accept").length;
    const reds = colorValues.filter((item) => item == "reject").length;

    return {
      greens: greens,
      reds: reds,
    };
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    console.log("submit");
    const dots = checkColor();
    if (dots.reds + dots.greens != buttonArray.length - 1) {
      showNotification("error", "Please complete the form");
      return;
    }
    try {
      const coHost = JSON.parse(data?.Co_Host || "");
      const payload = {
        crm_Project_Opportunity_ID: data?.crm_Project_Opportunity_ID || "",
        Co_Host: coHost.id || "",
        Status: "",
      };
      if (dots.greens == buttonArray.length - 1) {
        payload.Status = "Contract";
      } else {
        payload.Status = "Submitted: IF Review";
      }

      const result = await updateStatus(payload).unwrap();
      if (!result.success) {
        throw new Error("failed to update status");
      }
      showNotification("success", "From submitted successfully");
    } catch (err) {
      console.log(err);
      showNotification("error", "Error");
    }
  };

  return (
    <>
      <form onSubmit={onSubmit} style={{ display: gemsPopup ? "none" : "" }}>
        <div
          className={`${styles.popupContainer}`}
          onClick={() => setPopup(false)}
        >
          <div
            className={`${styles.popup} ${styles.gemsChecklistPopup} relative`}
            onClick={(e) => e.stopPropagation()}
          >
            <IoClose
              className="text-xl absolute right-2 -mt-2 text-slate-800 cursor-pointer"
              onClick={() => setPopup(false)}
            />
            <div className="w-[80vw] md:w-[50vw] h-80">
              <div className="h-full relative">
                <Popup
                  page={page}
                  setPage={setPage}
                  setColor={setColor}
                  docId={data?.Project_Opportunity_Doc_ID}
                  projectId={data?.crm_Project_Opportunity_ID}
                  submitting={submitting}
                />
              </div>
            </div>
            <div className="text-center">
              {buttonArray.map((item, index) => (
                <Dots
                  key={index}
                  color={color[item]}
                  currentPage={index + 1}
                  page={page}
                  setPage={setPage}
                />
              ))}
            </div>
          </div>
        </div>
      </form>

      {isLoading && (
        <div className="h-[100vh] w-[100%] absolute top-0 left-0">
          <Loader isLoading={isLoading} />
        </div>
      )}
    </>
  );
};
