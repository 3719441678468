// const envVar: AppConfig = require(`./env.${process.env.NODE_ENV}`).default;
// export default envVar;
const environment: AppConfig = require(`./env.${
  process.env.REACT_APP_NODE_ENV || "development"
}`).default;
console.log(environment, "environment");

console.log({ ENV: process.env.REACT_APP_NODE_ENV });

export const config = environment;
