import styles from "./index.module.css";
import { EPCProject } from "./EpcTable";
import { useAppSelector } from "../../../store/store";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { editUserSchema } from "../../../yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { config } from "../../../configs/index";
import { FaEdit, FaEye } from "react-icons/fa";
import { GemsPopup } from "./GemsPopup";
import {
  useAddDocumentMutation,
  useDeleteDocumentMutation,
  useDeleteHostDocsMutation,
  useDownloadDocumentFromContactsMutation,
  useDownloadDocumentMutation,
  useEditUserMutation,
  useEpcFileUploadMutation,
  useGetAllUploadsQuery,
  useGetHostDataQuery,
  useGetHostDocumentsQuery,
  useGetNotesQuery,
  useGetProjectIdDataMutation,
  useSendIsoftEmailMutation,
  useUpdateNotesMutation,
} from "../../../services/epcApi";
import Loader from "../../Loader";
import { MdDeleteOutline } from "react-icons/md";
import { showNotification } from "../../../services/showNotification";
import { IoIosSend } from "react-icons/io";

interface UploadFields {
  customerId: Obj[];
  auth: Obj[];
  homeOwner: Obj[];
  utility: Obj[];
  trust: Obj[];
  title: Obj[];
  proposal: Obj[];
  [key: string]: Obj[];
}

interface Obj {
  file_name: string;
  file_id: string;
}
interface GetFields {
  contact_name: string;
  contact_id: string;
  attachment_id: string;
  file_id: string;
  file_name: string;
}
type nameType =
  | "customerId"
  | "auth"
  | "homeOwner"
  | "utility"
  | "trust"
  | "title"
  | "proposal";

export const EpcPopUp = ({
  data,
  popup,
  setPopup,
}: {
  data: EPCProject | undefined;
  popup: boolean;
  setPopup: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isFileUploaded, setIsFileUploaded] = useState({
    customerId: false,
    auth: false,
    homeOwner: false,
    utility: false,
    trust: false,
    title: false,
    proposal: false,
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    setError,
    clearErrors,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editUserSchema),
    context: { isFileUploaded },
  });

  const epcDetails = useAppSelector((state) => state.epcUser);
  const epcName = epcDetails.firstName + " " + epcDetails.lastName;
  const navigate = useNavigate();
  const [notes, setNotes] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [attachments, setAttachments] = useState<IGetDocs[]>([]);

  // Api calls
  const { data: notesList, isLoading: notesLoading } = useGetNotesQuery(
    data?.crm_Project_Opportunity_ID || "",
    {
      skip: !notes,
    }
  );
  const {
    data: hostData,
    isLoading: hostLoading,
    refetch: refetchNames,
  } = useGetHostDataQuery(data?.crm_Project_Opportunity_ID || "");
  const {
    data: uploadsList,
    isLoading: docsLoading,
    refetch,
  } = useGetAllUploadsQuery(data?.Project_Opportunity_Doc_ID || "", {
    // skip: !data?.Project_Opportunity_Doc_ID,
  });
  const [updateNotes] = useUpdateNotesMutation();
  // const [epcFileUpload] = useEpcFileUploadMutation();
  const [deleteDocument] = useDeleteDocumentMutation();
  const [getSolarData, { data: projectData, isLoading: projectLoading }] =
    useGetProjectIdDataMutation();
  const [editUser] = useEditUserMutation();
  const [viewDocument, { data: fileDoc }] =
    useDownloadDocumentFromContactsMutation();
  const [addDocument] = useAddDocumentMutation();
  // const { data: attachments } = useGetHostDocumentsQuery(
  //   data?.crm_Project_Opportunity_ID || ""
  // );
  const [deleteHostDocs] = useDeleteHostDocsMutation();
  const [sendIsoftEmail, { isLoading: sendingEmail }] =
    useSendIsoftEmailMutation();

  // States
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("");
  const [solarIdLoading, setSolarIdLoading] = useState(false);
  const [gemsPopup, setGemsPopup] = useState(false);
  const [noteContent, setNoteContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<UploadFields>({
    customerId: [],
    auth: [],
    homeOwner: [],
    utility: [],
    trust: [],
    title: [],
    proposal: [],
  });
  const [mailLoading, setMailLoading] = useState(false);
  const [cohostMailLoading, setCohostMailLoading] = useState(false);

  // functions
  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const sendData = {
        Co_Host: data?.Co_Host,
        crm_Project_Opportunity_ID: data?.crm_Project_Opportunity_ID,
        Customer_ID: true,
        Utility_Authorization: true,
        GENS_Homeowner_Application: true,
        Utility_Bill: true,
        Title_Lease: true,
        Proposal: true,
        Status: "Submitted: IF Review",
      };

      const result: any = await editUser(sendData);
      if (result.data.data.status == 200) setSubmitStatus("submitted");
      else throw new Error("submission failed");
    } catch (err) {
      setSubmitStatus("error");
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (
    item: { file_name: string; file_id: string },
    e: React.MouseEvent<SVGElement, MouseEvent>
  ) => {
    //doc_id from data and attachment_id form state
    e.stopPropagation();
    const confirmed = window.confirm(
      "Are you sure you want to delete this file"
    );
    if (!confirmed) {
      return;
    }
    try {
      const sendData = {
        record_id: data?.Project_Opportunity_Doc_ID || "",
        attachment_id: item.file_id,
      };

      const result: any = await deleteDocument(sendData);
      if (result.data.data.status != 200)
        throw new Error("failed to delete document");

      const deleteData = {
        crmProjectOpportunityId: data?.crm_Project_Opportunity_ID || "",
        key: item.file_name,
        attachmentId: item.file_id,
      };
      const updatedHost = await deleteHostDocs(deleteData).unwrap();

      if (!updatedHost.data.acknowledged)
        throw new Error("deleted document from host");

      const updatedUploadedFileName = { ...uploadedFileName };

      const resultState: any = updatedUploadedFileName[item.file_name].filter(
        (value) => value.file_id != item.file_id
      );
      resultState.length == 0 && resetField(item.file_name as nameType);

      setUploadedFileName((prev: UploadFields) => ({
        ...prev,
        [item.file_name]: resultState,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const submitSolarId = async () => {
    setSolarIdLoading(true);
    try {
      let solarId = getValues("solarId");
      solarId = solarId?.trim();
      if (solarId == "") {
        setError("solarId", {
          type: "custom",
          message: "Solar id is required",
        });
        return;
      } else {
        clearErrors("solarId");
      }
      const requiredData = {
        solarId: solarId,
        crmProjectOpportunityId: data?.crm_Project_Opportunity_ID,
      };
      await getSolarData(requiredData);
      setValue("solarIdVerify", " ");
      clearErrors("solarIdVerify");
    } catch (error) {
      console.log(error);
    } finally {
      setSolarIdLoading(false);
    }
  };

  const getAttachmentNameById = (item: Obj) => {
    const attachment = attachments?.find(
      (att: IGetDocs) => att.attachmentId === item.file_id
    );
    return attachment ? attachment.name : item.file_name;
  };

  //when file is uploaded
  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    try {
      const name = e.target.name as nameType;

      clearErrors(name);
      const uploadedFile = e.target.files?.[0];
      if (uploadedFile && name) {
        setValue(name, uploadedFile);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("file", uploadedFile);
        formData.append(
          "projectOpportunityId",
          data?.crm_Project_Opportunity_ID || ""
        );
        const res = await fetch(
          `${config.REACT_APP_BASE_URL}/api/epc/epc-file-upload`,
          {
            method: "POST",
            body: formData,
          }
        );
        const result = await res.json();
        // const res = await epcFileUpload(formData)
        console.log("resssss", result);

        //insert new doc in backend
        const sendData: IAddDocument = {
          crmProjectOpportunityId: data?.crm_Project_Opportunity_ID || "",
          key: name,
          file: {
            name: uploadedFile.name,
            attachmentId: result.data.data[0].details.id,
          },
        };
        console.log("send-data", sendData);

        const response = await addDocument(sendData).unwrap();
        if (!response.success) {
          throw new Error("Failed to add data");
        }

        const updatedUploadedFileName = { ...uploadedFileName };

        updatedUploadedFileName[name].push({
          file_name: uploadedFile.name,
          file_id: result.data.data[0].details.id, //attachment-id
        });
        setUploadedFileName(updatedUploadedFileName);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    console.log("file", e.target.files?.[0]?.name);
  };

  const handleNote = async () => {
    if (!noteContent.trim()) return;
    setIsLoading(true);
    try {
      const sendData = {
        crm_Project_Opportunity_ID: data?.crm_Project_Opportunity_ID,
        notes: noteContent,
        // tile: 'title'
      };
      const result = await updateNotes(sendData).unwrap();
      if (!result.success) throw new Error("Failed to save notes");

      setNoteContent("");
      setNotes(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const setPreviousFile = async () => {
    // const updatedUploadedFileName = { ...uploadedFileName };
    const updatedUploadedFileName: UploadFields = {
      customerId: [],
      auth: [],
      homeOwner: [],
      utility: [],
      trust: [],
      title: [],
      proposal: [],
    };
    setUploadedFileName(updatedUploadedFileName);

    uploadsList?.data.forEach((item: UploadedFileRes) => {
      Object.keys(updatedUploadedFileName).forEach((key) => {
        if (item.file_name.toLowerCase().includes(key.toLowerCase())) {
          clearErrors(key as nameType);
          setIsFileUploaded((prev) => ({ ...prev, [key as nameType]: true }));
          updatedUploadedFileName[key].push({
            file_name: item.file_name,
            file_id: item.attachment_id,
          });
        }
      });
    });

    setUploadedFileName(updatedUploadedFileName);
  };

  const openFile = async (id: string) => {
    if (id == "") {
      showNotification("error", "Id not found");
      return;
    }
    const data = {
      attachment_id: id,
      crmContactId: epcDetails.crmContactId,
    };
    const response = (await viewDocument(data)) as { data: any };
    const arrayBuffer = response.data;
    if (!arrayBuffer) {
      throw new Error("File not downloaded");
    }

    let mimeType = "image/png";
    const blob = new Blob([arrayBuffer], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    console.log(url);
    const newTab = window.open();
    if (newTab) {
      newTab.location.href = url;
      newTab.onload = () => {
        window.URL.revokeObjectURL(url);
      };
    } else {
      console.error(
        "Failed to open new tab. Make sure pop-ups are not blocked."
      );
    }
  };

  const sendMail = async () => {
    setMailLoading(true);
    try {
      const sendData = {
        email: data?.Host_Email,
        firstName: data?.Host_First_Name,
        address: data?.Project_Street_Address,
        crmProjectOpportunityId: data?.crm_Project_Opportunity_ID,
        host: true,
      };

      const result = await sendIsoftEmail(sendData).unwrap();
    } catch (err) {
      console.log("error", err);
    }
    setMailLoading(false);
  };

  const sendCohostMail = async () => {
    setCohostMailLoading(true);
    try {
      const sendData = {
        email: data?.Co_Host_Email,
        firstName: data?.Co_Host_First_Name,
        address: data?.Project_Street_Address,
        crmProjectOpportunityId: data?.crm_Project_Opportunity_ID,
        host: false,
      };

      const result = await sendIsoftEmail(sendData).unwrap();
      console.log("resea", result);
    } catch (err) {
      console.log("error", err);
    }
    setCohostMailLoading(false);
  };

  const [downloadDocument] = useDownloadDocumentMutation();

  const viewFiles = async (item: string) => {
    setIsLoading(true);
    try {
      const sendData = {
        attachment_id: item,
        crm_Project_Opportunity_ID: data?.crm_Project_Opportunity_ID || "",
      };
      const response = (await downloadDocument(sendData)) as { data: any };
      const file = response.data;

      if (!file) {
        throw new Error("File not downloaded");
      }

      const blob = new Blob([file], { type: "image/jpeg" });
      const url = window.URL.createObjectURL(blob);

      window.open(url, "_blank");
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refetchNames();
    uploadsList && uploadsList?.data?.length > 0 && setPreviousFile();
  }, [uploadsList]);

  useEffect(() => {
    data?.Status == "Submitted: IF Review" && setConfirmPopup(true);
    refetch();
  }, []);

  useEffect(() => {
    !popup && refetch();
  }, [popup]);

  useEffect(() => {
    if (hostData?.data?.solarId) {
      reset({ solarId: hostData?.data?.solarId });
    }
    if (hostData?.data?.customerId) {
      const { data } = hostData;
      const arr = [
        ...data?.customerId,
        ...data?.auth,
        ...data?.homeOwner,
        ...data?.utility,
        ...data?.trust,
        ...data?.title,
        ...data?.proposal,
      ];
      setAttachments(arr);
    }
  }, [hostData]);

  return (
    <>
      {confirmPopup ? (
        <div className={`${styles.popupContainer}`}>
          <div className={`${styles.popup} ${styles.confirmPopup}`}>
            <p className=" sm:m-8 my-8 text-xl">
              Would you like to edit the user details?
            </p>
            <div className="flex justify-between m-8">
              <button
                className="bg-green-700 text-white h-10 w-20 rounded-md"
                onClick={() => setConfirmPopup(false)}
              >
                Yes
              </button>
              <button
                className="border-2 border-red-400 text-white h-10 w-20 rounded-md"
                onClick={() => setPopup(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: gemsPopup ? "none" : "" }}
        >
          <div
            className={styles.popupContainer}
            onClick={() => setPopup(false)}
          >
            {notesLoading || docsLoading || hostLoading ? (
              <div className="h-[100vh] w-[100%] absolute top-0 left-0">
                <Loader
                  isLoading={notesLoading || docsLoading || hostLoading}
                  size={48}
                />
              </div>
            ) : (
              <div
                className={`${styles.popup} overflow-y-auto`}
                onClick={(e) => e.stopPropagation()}
              >
                <div>
                  <h6 className={styles.heading}>Contact Details</h6>
                  <div className={styles.name}>
                    <div>
                      <div className="flex justify-between items-center">
                        <div>
                          <span className="text-white font-bold">NAME :</span>{" "}
                          {data?.Host_First_Name + " " + data?.Host_Last_Name}
                        </div>
                        {/* <span
                          className="text-white cursor-pointer"
                        >
                          {mailLoading ? 
                            <Loader isLoading={true} size={14} /> :
                            // <IoIosSend onClick={sendMail} />
                            <span className="border">Send mail</span>
                          }
                        </span> */}
                        <button
                          type="button"
                          onClick={sendMail}
                          className="text-[13px] border rounded-md border-gray-600 w-[70px]"
                        >
                          {mailLoading ? (
                            <Loader isLoading={true} size={13} />
                          ) : (
                            "Send Email"
                          )}
                        </button>
                      </div>
                      <div>
                        <span className="text-white font-bold">EMAIL :</span>{" "}
                        {data?.Host_Email}
                      </div>
                      <div>
                        <span className="text-white font-bold">CREDIT* :</span>{" "}
                        {data?.Project_Credit_Status +
                          " " +
                          data?.MODIFIEDTIME.substring(0, 10)}
                      </div>
                      <div className="flex items-center justify-start">
                        <span className="text-white font-bold">
                          OPEN-SOLAR ID* :
                        </span>
                        <input
                          type="text"
                          {...register("solarId")}
                          className="border border-gray-600 rounded-md w-[100px] py-[2px] h-[1.3rem] bg-transparent p-1 text-sm mx-1"
                        />
                        <button
                          type="button"
                          onClick={submitSolarId}
                          className="text-[13px] border rounded-md border-gray-600 w-[70px]"
                        >
                          {solarIdLoading ? (
                            <Loader isLoading={true} size={13} />
                          ) : (
                            "SUBMIT"
                          )}
                        </button>
                      </div>
                      {(errors.solarIdVerify || errors.solarId) && (
                        <div className="text-red-500 text-sm">
                          {errors.solarId?.message ||
                            errors.solarIdVerify?.message}
                        </div>
                      )}
                      <div className={`${styles.fileUploader} w-full`}>
                        <span className="text-white font-bold">
                          CUSTOMER ID* :{" "}
                        </span>
                        <label htmlFor="customerId" className={styles.uploads}>
                          Upload
                        </label>
                        <input
                          id="customerId"
                          {...register("customerId")}
                          name="customerId"
                          type="file"
                          onChange={onChange}
                          hidden
                        />
                      </div>
                      {errors.customerId && (
                        <div className="text-red-500 text-sm">
                          {errors.customerId.message}
                        </div>
                      )}
                      {uploadedFileName.customerId.length > 0 && (
                        <div>
                          {uploadedFileName.customerId.map((item, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => viewFiles(item.file_id)}
                                className="flex items-center justify-between w-full p-1 m-1 text-sm text-white border border-gray-600 rounded-md cursor-pointer"
                              >
                                {getAttachmentNameById(item).substring(0, 30)}
                                <MdDeleteOutline
                                  className="text-red-500 text-lg cursor-pointer"
                                  onClick={(e) => handleDelete(item, e)}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div className={styles.cosignerBox}>
                      {data?.Co_Host ? (
                        <div>
                          <div className="flex justify-between items-center">
                            <div>
                              <span className="text-white font-bold">
                                COSIGNER NAME :
                              </span>{" "}
                              {data?.Co_Host_First_Name +
                                " " +
                                data?.Co_Host_Last_Name}
                            </div>
                            {/* <span
                              className="text-white cursor-pointer"
                            >
                              {cohostMailLoading ? 
                                <Loader isLoading={true} size={14} /> :
                                <IoIosSend onClick={sendCohostMail} />
                              }
                            </span> */}
                          </div>
                          <div>
                            <span className="text-white font-bold">
                              COSIGNER EMAIL :
                            </span>{" "}
                            {data?.Co_Host_Email}
                          </div>
                          <div className="flex">
                            <span className="text-white font-bold">
                              COSIGNER ID :
                            </span>{" "}
                            <div
                              onClick={() =>
                                openFile(hostData?.data.cohostDocId || "")
                              }
                              className="rounded-md flex items-center border border-gray-600 px-2 mx-2 cursor-pointer"
                            >
                              <FaEye className="mx-1" />
                              View
                            </div>
                          </div>
                          <div className="flex justify-between mt-1">
                            <button
                              type="button"
                              onClick={sendCohostMail}
                              className="text-[13px] border rounded-md border-gray-600 w-[70px]"
                            >
                              {cohostMailLoading ? (
                                <Loader isLoading={true} size={13} />
                              ) : (
                                "Send Email"
                              )}
                            </button>
                            <button
                              onClick={() =>
                                navigate("/epc/co-host", {
                                  state: {
                                    projectId: data?.crm_Project_Opportunity_ID,
                                    cohostId: data?.Co_Host,
                                    firstName: data?.Co_Host_First_Name,
                                    lastName: data?.Co_Host_Last_Name,
                                    email: data?.Co_Host_Email,
                                    id: hostData?.data.cohostDocId || "",
                                  },
                                })
                              }
                            >
                              <FaEdit className="text-xm  text-white" />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <button
                          onClick={() =>
                            navigate("/epc/co-host", {
                              state: {
                                projectId: data?.crm_Project_Opportunity_ID,
                                cohostId: data?.Co_Host,
                                firstName: data?.Co_Host_First_Name,
                                lastName: data?.Co_Host_Last_Name,
                                email: data?.Co_Host_Email,
                                id: hostData?.data.cohostDocId || "",
                              },
                            })
                          }
                          className={styles.cosigner}
                        >
                          Add-Cosigner
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <h6 className={styles.heading}>Project Details</h6>
                  <div>
                    <div
                      className={`${styles.fileUploader} w-full flex flex-col items-start `}
                    >
                      <div>
                        <span className="text-white font-bold">
                          UTILITY AUTH FORM* :
                        </span>
                        <label htmlFor="auth" className={styles.uploads}>
                          Upload
                        </label>
                        <input
                          id="auth"
                          {...register("auth")}
                          name="auth"
                          type="file"
                          onChange={onChange}
                          hidden
                        />
                      </div>
                      {errors.auth && (
                        <div className="text-red-500 text-sm">
                          {errors?.auth?.message}
                        </div>
                      )}
                      {uploadedFileName.auth.length > 0 && (
                        <div className="w-1/2">
                          {uploadedFileName.auth.map((item, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => viewFiles(item.file_id)}
                                className="flex items-center justify-between w-full p-1 m-1 text-sm text-white border border-gray-600 rounded-md cursor-pointer"
                              >
                                {getAttachmentNameById(item).substring(0, 30)}
                                <MdDeleteOutline
                                  onClick={(e) => handleDelete(item, e)}
                                  className="text-red-500 text-lg cursor-pointer"
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div
                      className={`${styles.fileUploader} w-full flex flex-col items-start `}
                    >
                      <div>
                        <span className="text-white font-bold">
                          HOMEOWNER APPLICATION* :
                        </span>
                        <label htmlFor="homeOwner" className={styles.uploads}>
                          Upload
                        </label>
                        <input
                          id="homeOwner"
                          {...register("homeOwner")}
                          name="homeOwner"
                          type="file"
                          onChange={onChange}
                          hidden
                        />
                      </div>
                      {errors.homeOwner && (
                        <div className="text-red-500 text-sm">
                          {errors?.homeOwner?.message}
                        </div>
                      )}
                      {uploadedFileName.homeOwner.length > 0 && (
                        <div className="w-1/2">
                          {uploadedFileName.homeOwner.map((item, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => viewFiles(item.file_id)}
                                className="flex items-center justify-between w-full p-1 m-1 text-sm text-white border border-gray-600 rounded-md cursor-pointer"
                              >
                                {getAttachmentNameById(item).substring(0, 30)}
                                <MdDeleteOutline
                                  onClick={(e) => handleDelete(item, e)}
                                  className="text-red-500 text-lg cursor-pointer"
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div>
                      <span className="text-white font-bold">
                        BLENDED RATE* :
                      </span>
                      {data?.Project_Blended_Utility_Rate} $/kWh
                    </div>
                    <div
                      className={`${styles.fileUploader} w-full flex flex-col items-start `}
                    >
                      <div>
                        <span className="text-white font-bold">
                          UTILITY BILL* :
                        </span>
                        <label htmlFor="utility" className={styles.uploads}>
                          Upload
                        </label>
                        <input
                          id="utility"
                          {...register("utility")}
                          name="utility"
                          type="file"
                          onChange={onChange}
                          hidden
                        />
                      </div>
                      {errors.utility && (
                        <div className="text-red-500 text-sm">
                          {errors?.utility?.message}
                        </div>
                      )}
                      {uploadedFileName.utility.length > 0 && (
                        <div className="w-1/2">
                          {uploadedFileName.utility.map((item, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => viewFiles(item.file_id)}
                                className="flex items-center justify-between w-full p-1 m-1 text-sm text-white border border-gray-600 rounded-md cursor-pointer"
                              >
                                {getAttachmentNameById(item).substring(0, 30)}
                                <MdDeleteOutline
                                  onClick={(e) => handleDelete(item, e)}
                                  className="text-red-500 text-lg cursor-pointer"
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div
                      className={`${styles.fileUploader} w-full flex flex-col items-start `}
                    >
                      <div>
                        <span className="text-white font-bold">TRUST :</span>
                        <label htmlFor="trust" className={styles.uploads}>
                          Upload
                        </label>
                        <input
                          id="trust"
                          {...register("trust")}
                          name="trust"
                          type="file"
                          onChange={onChange}
                          hidden
                        />
                      </div>
                      {errors.trust && (
                        <div className="text-red-500 text-sm">
                          {errors?.trust?.message}
                        </div>
                      )}
                      {uploadedFileName.trust.length > 0 && (
                        <div className="w-1/2">
                          {uploadedFileName.trust.map((item, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => viewFiles(item.file_id)}
                                className="flex items-center justify-between w-full p-1 m-1 text-sm text-white border border-gray-600 rounded-md cursor-pointer"
                              >
                                {getAttachmentNameById(item).substring(0, 30)}
                                <MdDeleteOutline
                                  onClick={(e) => handleDelete(item, e)}
                                  className="text-red-500 text-lg cursor-pointer"
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div
                      className={`${styles.fileUploader} w-full flex flex-col items-start `}
                    >
                      <div>
                        <span className="text-white font-bold">
                          TITLE/LEASE* :
                        </span>
                        <label htmlFor="title" className={styles.uploads}>
                          Upload
                        </label>
                        <input
                          id="title"
                          {...register("title")}
                          name="title"
                          type="file"
                          onChange={onChange}
                          hidden
                        />
                      </div>
                      {errors.title && (
                        <div className="text-red-500 text-sm">
                          {errors?.title?.message}
                        </div>
                      )}
                      {uploadedFileName.title.length > 0 && (
                        <div className="w-1/2">
                          {uploadedFileName.title.map((item, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => viewFiles(item.file_id)}
                                className="flex items-center justify-between w-full p-1 m-1 text-sm text-white border border-gray-600 rounded-md cursor-pointer"
                              >
                                {getAttachmentNameById(item).substring(0, 30)}
                                <MdDeleteOutline
                                  onClick={(e) => handleDelete(item, e)}
                                  className="text-red-500 text-lg cursor-pointer"
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      <span className="text-white font-bold">SALES/REP : </span>
                      {epcName}
                    </div>
                    <div>
                      <span className="text-white font-bold">
                        SITE ADDRESS* :
                      </span>{" "}
                      {data?.Project_Street_Address}
                    </div>
                    <div>
                      <span className="text-white font-bold">
                        ONLINE RATE* :
                      </span>{" "}
                      {"__" + " " + data?.MODIFIEDTIME.substring(0, 10)}
                    </div>
                    <div>
                      <span className="text-white font-bold">
                        ONLINE SYSTEM* :
                      </span>{" "}
                      ${data?.Project_TurnKey_EPC_Price} EPC cost, __, __
                    </div>
                    <div
                      className={`${styles.fileUploader} w-full flex flex-col items-start `}
                    >
                      <div>
                        <span className="text-white font-bold">
                          PROPOSAL* :
                        </span>
                        <label htmlFor="proposal" className={styles.uploads}>
                          Upload
                        </label>
                        <input
                          id="proposal"
                          {...register("proposal")}
                          name="proposal"
                          type="file"
                          onChange={onChange}
                          hidden
                        />
                      </div>
                      {errors.proposal && (
                        <div className="text-red-500 text-sm">
                          {errors?.proposal?.message}
                        </div>
                      )}
                      {uploadedFileName.proposal.length > 0 && (
                        <div className="w-1/2">
                          {uploadedFileName.proposal.map((item, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => viewFiles(item.file_id)}
                                className="flex items-center justify-between w-full p-1 m-1 text-sm text-white border border-gray-600 rounded-md cursor-pointer"
                              >
                                {getAttachmentNameById(item).substring(0, 30)}
                                <MdDeleteOutline
                                  onClick={(e) => handleDelete(item, e)}
                                  className="text-red-500 text-lg cursor-pointer"
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div className="flex text-white font-bold justify-between">
                      NOTES: Click the edit button to add notes
                      <div className="text-right mt-1">
                        <div
                          className="flex items-center "
                          onClick={() => setNotes((prev) => !prev)}
                        >
                          <FaEdit className="text-xm cursor-pointer" />
                        </div>
                      </div>
                    </div>
                    {notes && (
                      <div className="flex flex-col items-ens my-1 w-full">
                        <textarea
                          className="border border-gray-600 bg-transparent px-2 py-1 w-full"
                          name="note"
                          id="note"
                          cols={50}
                          rows={3}
                          value={noteContent}
                          onChange={(e) => setNoteContent(e.target.value)}
                        />
                        <button
                          type="button"
                          className="border border-gray-600 w-[100px] ml-auto mt-2 rounded-md"
                          onClick={handleNote}
                        >
                          SAVE
                        </button>
                        {notesList?.data && notesList?.data?.length > 0 && (
                          <div className="border border-gray-600 bg-transparent px-2 mt-2 py-1 w-full">
                            <p className="w-full text-lg text-white text-center">
                              Previous Reply
                            </p>
                            {!notesList.data.length && (
                              <p className="text-center">No note found</p>
                            )}
                            {notesList.data.map((item: any) => {
                              return (
                                <div className="text-mg p-1 m-1">
                                  {item.Content}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )}
                    <div
                      className={styles.instructions}
                      onClick={() => setGemsPopup(true)}
                    >
                      GEMS Application Checklist
                    </div>
                  </div>
                </div>
                <button type="submit" className={styles.btn}>
                  SUBMIT APPLICANT ELIGIBILITY (step 1)
                </button>
                {/* <div className={styles.instructions}>*Required fields</div> */}
                {submitStatus === "submitted" && (
                  <div className="text-center text-green-700 bg-green-200 border-green-700 border-2 rounded-md mt-2">
                    User updated successfully
                  </div>
                )}
                {submitStatus === "error" && (
                  <div className="text-center text-red-700 bg-red-300 border-red-700 border-2 rounded-md mt-2">
                    Submission failed
                  </div>
                )}
                {/* <button type="button" className={styles.btn} onClick={sendMail} disabled={sendingEmail}>
                  {sendingEmail ? <Loader isLoading={true} /> :
                  'Resubmit Credit Qualification'}
                </button> */}
              </div>
            )}
          </div>
        </form>
      )}

      {isLoading && (
        <div className="h-[100vh] w-[100%] absolute top-0 left-0">
          <Loader isLoading={isLoading} size={48} />
        </div>
      )}

      {gemsPopup && <GemsPopup setGemsPopup={setGemsPopup} />}
    </>
  );
};
