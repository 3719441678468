import { useNavigate } from "react-router-dom";
import img from "../../../assets/images/InceptionFinicallyLogo.png";
import { colors } from "../../../utils/theme";
import CustomButton from "../../Button/CustomButton";
import CustomInput from "../../CustomInput";
import styles from "./index.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { epcLoginSchema } from "../../../yup";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setEpcUser } from "../../../store/reducers/epcUserReducer";
import { useLoginMutation } from "../../../services/epcApi";

const EpcLogin = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(epcLoginSchema),
  });

  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [login] = useLoginMutation();

  const onSubmit = async (values: any) => {
    console.log(values);
    localStorage.removeItem("epcUserDetail");
    try {
      setIsLoading(true);
      // const res = await fetch(`${config.REACT_APP_BASE_URL}/api/epc/epc-login`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/x-www-form-urlencoded",
      //   },
      //   body: new URLSearchParams(values),
      // });
      // const result = await res.json();
      const result = await login(values).unwrap();
      if (result.success) {
        const userDetails = {
          email: result.data.email,
          crmContactId: result.data.crmContactId,
          firstName: result.data.firstName,
          lastName: result.data.lastName,
          contactGroup: result.data.contactGroup,
          contactType: result.data.contactType,
        };
        localStorage.setItem("token", result.data.token);

        dispatch(setEpcUser(userDetails));
        // sessionStorage.setItem("epcUser", values.email);
        reset();
        setErrMsg("");
        if (userDetails.contactType.toLowerCase() == "gems")
          navigate("/epc/gem-dashboard");
        else navigate("/epc/dashboard");
      } else {
        throw new Error(`${result.message}`);
      }
    } catch (error) {
      console.error("Login error:", error);
      setErrMsg("An error occurred during login.");
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setValue(name, value);
    setErrMsg("");
  };

  return (
    <div className="w-full flex items-center justify-center">
      <div className="w-[80vw] h-[80vh] relative flex flex-col items-center justify-center">
        <div className="flex justify-start px-10 pb-20 absolute top-0 left-0">
          <img src={img} alt="" className={`${styles.image}`} />
        </div>
        <form
          className="flex flex-col h-1/2 w-full md:w-1/2 items-center justify-center"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className="w-[70%] mb-8">
            <CustomInput
              placeholder="Email"
              name="email"
              type="email"
              register={register}
              error={errors.email}
              onChange={onChange}
            />
          </div>
          <div className="w-[70%] mb-2">
            <CustomInput
              placeholder="Password"
              name="password"
              type="password"
              register={register}
              error={errors.password}
              onChange={onChange}
            />
          </div>
          <div className="flex flex-col items-center justify-center md:block w-[70%] mt-5">
            {errMsg && (
              <p className="text-red-500 font-bold text-md mb-4">{errMsg}</p>
            )}
            <CustomButton buttonLabel="Login" isLoading={isLoading} />
          </div>
          <a
            href={"/epc/forgot-password"}
            className={`text-[${colors.green}] font-normal underline w-1/2 flex items-center justify-center text-xl sm:text-[16px]`}
          >
            Forgot password?
          </a>
        </form>
      </div>
    </div>
  );
};

export default EpcLogin;
