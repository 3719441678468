import { SetStateAction, Dispatch, useState, useEffect, useRef } from "react";
import styles from "./index.module.css";
import CustomButton from "../Button/CustomButton";
import { MdDownload } from "react-icons/md";
import { GrAttachment } from "react-icons/gr";
import { IoMdSend, IoMdDownload } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  useAddCommentMutation,
  useDownloadDriverIdMutation,
  useUploadLicenceMutation,
} from "../../services/api";
import { showNotification } from "../../services/showNotification";
import { config } from "../../configs/index";
interface IChat {
  message: string;
  replyTime: string;
  attachmentId?: string;
  senderId: string;
}
interface Props {
  setPopup: Dispatch<SetStateAction<boolean>>;
  data: EntityInformation | undefined;
  // setIsRejectClicked: Dispatch<SetStateAction<boolean>>;
  setIsCompletedClicked: Dispatch<SetStateAction<boolean>>;
  downloadLoader: boolean;
  downloadAttachment: (
    e: any,
    fileId: string,
    zohoId: string,
    index: number
  ) => void;
  refetch: any;
}

const EntityTablePopup = ({
  setPopup,
  data,
  // setIsRejectClicked,
  setIsCompletedClicked,
  downloadLoader,
  downloadAttachment,
  refetch,
}: Props) => {
  const { register, watch, resetField, reset } = useForm();
  const token = localStorage.getItem("token") || "";
  const zohoCustomerId = useSelector(
    (state: RootState) => state.login.zohoCustomerId
  );
  const [uploadAttachment, { isLoading }] = useUploadLicenceMutation();
  const [addComment, { isLoading: commentLoading, isSuccess, isError }] =
    useAddCommentMutation();
  const [downloadId] = useDownloadDriverIdMutation();

  const [attachmentId, setAttachmentId] = useState("");
  const [chat, setChat] = useState<IChat[]>(data?.chat || []);
  const [initiateScroll, setInitiateScroll] = useState(false);
  const [isAttachment, setIsAttachment] = useState(false);

  const onClose = () => {
    setPopup(false);
  };
  // const entityRejected = () => {
  //   setIsRejectClicked(true);
  //   setPopup(false);
  // };
  const entityCompleted = () => {
    setIsCompletedClicked(true);
    setPopup(false);
  };

  const uploadFile = async (file: File) => {
    const formData = new FormData();
    file && formData.append("file", file);
    formData.append("contactId", zohoCustomerId);
    resetField("attachment");

    try {
      const response: any = await uploadAttachment(formData);
      console.log(response);

      const id = response.data.data.details.id;
      setAttachmentId(id);
      console.log(id, "attachmentId");
      return id;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const getCurrentDateTime = () => {
    const date = new Date();

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${month}-${day}-${year} ${hours}:${minutes}`;
  };

  const addChat = async (zohoId: string) => {
    const fileObj = watch("attachment");
    const file = fileObj?.[0];
    console.log(file, "file");
    let attachmentId = "";
    if (file) {
      try {
        attachmentId = await uploadFile(file);
        setIsAttachment(false);
        resetField("attachment");
      } catch (error) {
        console.log(error, "error");
      }
    }
    const message = watch("message");
    resetField("message");
    const data = {
      message: message,
      attachmentId: attachmentId,
      replyTime: await getCurrentDateTime(),
      senderId: zohoCustomerId,
      zohoId: zohoId,
    };
    const newChat = [...chat];
    newChat.push(data);
    setChat(newChat);
    reset();
    setIsAttachment(false);
    const response = await addComment(data);
    refetch();
  };

  const onAttachmentClose = () => {
    setIsAttachment(false);
    resetField("attachment");
  };

  const downloadAttachments = async (fileId: string, zohoId: string) => {
    const data = {
      fileId: fileId,
      zohoId: zohoId,
    };
    try {
      const response = await fetch(
        `${config.REACT_APP_BASE_URL}/api/user/download-driving-licence`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            observe: "response",
            responseType: "blob",
          },
        }
      );
      // const response: any = await downloadId(data);
      const arrayBuffer = await response.arrayBuffer();
      const blob = new Blob([arrayBuffer], {
        type: "application/octet-stream",
      });

      const url = window.URL.createObjectURL(blob);
      console.log(url, "URL");
      const a = document.createElement("a");
      a.href = url;
      a.download = "Attachment";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown =
    (zohoId: string) => (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
        addChat(zohoId);
      }
    };
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      setInitiateScroll(true);
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  useEffect(() => {
    scrollToBottom();
  }, [initiateScroll]);

  useEffect(() => {
    const fileObj = watch("attachment");
    const file = fileObj?.[0];
    if (file != null) {
      setIsAttachment(true);
      // uploadFile(file);
    }
  }, [watch("attachment")]);

  useEffect(() => {
    if (isError) {
      showNotification("error", "An error occured");
      chat.pop();
    }
  }, [isError]);

  const fileObj = watch("attachment");
  const file = fileObj?.[0];

  return (
    <>
      <div className={styles.overlay} onClick={onClose}>
        <div
          className={styles.overlayContent}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="sticky -top-4 text-right -mt-8">
            <span
              className="sticky text-2xl -mr-3 cursor-pointer bg-white"
              onClick={onClose}
            >
              &times;
            </span>
          </div>
          <div className={styles.titleBox}>
            <div className={styles.labels}>Email</div>
            <div className={styles.inputs}>{data?.email}</div>
          </div>
          <div className={styles.titleBox}>
            <div className={styles.labels}>
              Corporation or Limited Liability Company?
            </div>
            <div className={styles.inputs}>
              {data?.isCorporation
                ? "Corporation"
                : "Limited Liability Company"}
            </div>
          </div>
          <div className={styles.titleBox}>
            <div className={styles.labels}>
              What is the primary type of business (i.e. Construction Company,
              Clothing Store, Holding Company, etc.)
            </div>
            <div className={styles.inputs}>{data?.businessType || "na"}</div>
          </div>
          <div className={styles.titleBox}>
            <div className={styles.labels}>
              What State would you like the company formed?
            </div>
            <div className={styles.inputs}>
              {data?.companyBuiltState || "na"}
            </div>
          </div>
          <div className={styles.titleBox}>
            <div className={styles.labels}>
              Would you like us to "Qualify" or "Register" your new company to
              do business in any other states? If so, where?
            </div>
            <div className={styles.inputs}>
              {data?.qualityRegisterNewCompany || "na"}
            </div>
          </div>
          <div className={styles.titleBox}>
            <div className={styles.labels}>Potential Company Name 1</div>
            <div className={styles.inputs}>
              {data?.potentialCompanyName1 || "na"}
            </div>
          </div>
          <div className={styles.titleBox}>
            <div className={styles.labels}>Potential Company Name 2</div>
            <div className={styles.inputs}>
              {data?.potentialCompanyName2 || "na"}
            </div>
          </div>
          <div className={styles.titleBox}>
            <div className={styles.labels}>Potential Company Name 3</div>
            <div className={styles.inputs}>
              {data?.potentialCompanyName3 || "na"}
            </div>
          </div>
          <div className={styles.titleBox}>
            <div className={styles.labels}>Mailing Address of New Company</div>
            <div className={styles.inputs}>
              {data?.companyMailingAddress || "na"}
            </div>
          </div>
          <div className={styles.titleBox}>
            <div className={styles.labels}>
              Please provide your Social Security Number (For the Company
              Employer Identification Application - EIN).
            </div>
            <div className={styles.inputs}>
              {data?.socialSecurityNumber || "na"}
            </div>
          </div>
          <div className={styles.titleBox}>
            <div className={styles.labels}>
              Do you require a Registered Agent?
            </div>
            <div className={styles.inputs}>
              {data?.registerAgentRequired ? "Yes" : "No"}
            </div>
          </div>
          {data?.registerAgentRequired ? (
            <div className={styles.titleBox}>
              <div className={styles.labels}>
                Are you okay paying a $125/year fee for us to contract an agent?
              </div>
              <div className={styles.inputs}>
                {data?.isContractAgentFee ? "Yes" : "No"}
              </div>
            </div>
          ) : (
            <div className={styles.titleBox}>
              <div className={styles.labels}>
                Legal name of all Members, Managers, Shareholders
              </div>
              <div className={styles.inputs}>
                {data?.membersLegalName || "na"}
              </div>
            </div>
          )}
          <div className={styles.titleBox}>
            <div className={styles.labels}>
              Who will act as your registered agent? (Full Legal Name)
            </div>
            <div className={styles.inputs}>
              {data?.registerAgentFullName || "na"}
            </div>
          </div>
          <div className={styles.titleBox}>
            <div className={styles.labels}>
              Legal name of all Members, Managers, Shareholders
            </div>
            <div className={styles.inputs}>
              {data?.membersLegalName || "na"}
            </div>
          </div>

          <div className={styles.titleBox}>
            <div className={styles.labels}>ID Download</div>
            {downloadLoader ? (
              <CustomButton
                buttonLabel=""
                isLoading={true}
                style={{ backgroundColor: "white", width: "8rem" }}
              />
            ) : (
              <div
                className=" bg-[#3B755E] rounded-md p-2 flex w-[8rem] justify-evenly text-white"
                onClick={(e) =>
                  downloadAttachment(
                    e,
                    data?.driverLicence || "",
                    data?.zohoCustomerId || "",
                    -1
                  )
                }
              >
                <MdDownload className="text-2xl" />
                <span>Download</span>
              </div>
            )}
          </div>
          <div className="w-full">
            <div className={styles.labels}>Reply</div>
            <div className="m-2 border border-gray-700 bg-[#f0f0f0] rounded-md p-4 pb-1">
              <div className="h-[200px] overflow-y-auto">
                {chat.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`p-2 text-md my-2 text-gray-800 rounded-md w-fit ${
                        zohoCustomerId == item.senderId
                          ? "bg-[#e6e6fa] ml-auto"
                          : "bg-[#fff9c4]"
                      } `}
                    >
                      {item.message}
                      {item.attachmentId && (
                        <div
                          onClick={() =>
                            downloadAttachments(
                              item.attachmentId || "",
                              data?.zohoCustomerId || ""
                            )
                          }
                          className="w-full flex justify-between items-center p-1 mt-2 mb-1 border border-gray-600 rounded-md cursor-pointer"
                        >
                          <span className="text-md">ATTACHMENT</span>
                          <IoMdDownload />
                        </div>
                      )}
                      <div className="bottom-0 right-0 text-xs text-end text-gray-500">
                        {item.replyTime}
                      </div>
                    </div>
                  );
                })}
                <div ref={chatEndRef} />
              </div>
              {isAttachment && isLoading && (
                <div className=" w-full py-1 px-2 mb-1 bg-[#90EE90] rounded-md">
                  <div className="text-center w-full text-xl text-gray-800 m-auto">
                    SENDING ...
                  </div>
                </div>
              )}
              {isAttachment && !isLoading && (
                <div className=" w-full py-1 px-2 mb-1 bg-[#90EE90] rounded-md">
                  <IoClose
                    className="text-xl ml-auto"
                    onClick={onAttachmentClose}
                  />
                  <div className="text-md text-gray-700">
                    {file?.name || ""}
                  </div>
                </div>
              )}
              <div className="w-full rounded-full p-2 bg-gray-300 flex items-center">
                <input
                  type="file"
                  className="hidden"
                  id="fileUpload"
                  // onChange={uploadFile}
                  {...register("attachment")}
                />
                <label htmlFor="fileUpload">
                  <GrAttachment className="text-xl w-[50px]" />
                </label>
                <input
                  {...register("message")}
                  type="text"
                  className="w-full p-1 rounded-md"
                  onKeyDown={handleKeyDown(data?.zohoCustomerId || "")}
                />
                <IoMdSend
                  className="text-2xl w-[50px]"
                  onClick={() => addChat(data?.zohoCustomerId || "")}
                />
              </div>
            </div>
          </div>
          {data?.entityStatus == "Open" && (
            <div className="flex justify-around items-center mt-10 text-white">
              <button
                onClick={entityCompleted}
                className="h-[40px] w-[8rem] bg-[#3B755E] rounded-lg"
              >
                Accept
              </button>
              {/* <button
                onClick={entityRejected}
                className="h-[40px] w-[8rem] bg-red-500 rounded-lg"
              >
                Reject
              </button> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default EntityTablePopup;
