import PdfImg from "../../assets/images/PdfLogo.png";
import Button from "../ButtonV2";
import moment from "moment-timezone";
import {
  useGetPastReportsQuery,
  useSubmitFormMutation,
} from "../../services/api";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateFormData } from "../../store/reducers/stageReducer";
import RunNewForma from "../RunNewForma";
import Step3_1 from "../TableData";
import { config } from "../../configs/index";

interface Props {
  lastName: string;
  openOverlay1: (val: any) => void;
}

const Tile3 = ({ lastName, openOverlay1 }: Props) => {
  const [result, { isLoading }] = useSubmitFormMutation();
  const { data: reportData } = useGetPastReportsQuery({
    page: 1,
  });
  const dispatch = useDispatch();
  const token = localStorage.getItem("token") || "";

  const [data, setData] = useState([]);
  const [table, setTable] = useState<FormResponse>();
  const [tile3DownloadLoader, setTile3DownloadLoader] = useState(false);

  const handleSubmitView = async (values: any) => {
    try {
      const newValues = { ...values };
      newValues.equityContribution = 0;
      newValues.taxRate = values?.estFederalTaxRate.toString();
      newValues.stateId = values?.stateId._id;
      newValues.grossIncome =
        values.grossIncome == null ? 0 : values.grossIncome;
      const data = (await result(newValues)) as Response<FormResponse>;

      if (data.data?.success) {
        dispatch(updateFormData(data?.data?.data));
        openOverlay1(<Step3_1 />);
      }
    } catch (error) {
      console.error("Error handling form submission:", error);
    }
  };

  const getExcel = async (row: any) => {
    setTile3DownloadLoader(true);
    try {
      const response = await fetch(
        `${config.REACT_APP_BASE_URL}/api/user/download-report`,
        {
          method: "POST",
          body: JSON.stringify({ id: row?._id }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response || !response.body) {
        console.log("Something Went Wrong");
        return;
      }
      console.log(response.body, "response body");
      const contentLength = +response.headers.get("content-length");
      const reader = response.body.getReader();
      let receivedLength = 0;
      const chunks = [];
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks.push(value);
        receivedLength += value.length;
      }

      const newDate = moment(row?.createdAt).format("MM.DD.YYYY");
      const fileName =
        lastName + "-" + row?.inputId?.equalityContribution + "-" + newDate;

      const blob = new Blob(chunks);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.xlsx`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("Error downloading Excel file");
    } finally {
      setTile3DownloadLoader(false);
    }
  };
  const handleDownload = (row: any) => {
    getExcel(row);
  };

  const handleProma = () => {
    openOverlay1(<RunNewForma setTable={setTable} setData={setData} />);
  };

  const transformData = () => {
    if (reportData?.data?.data) {
      const data = reportData?.data?.data;
      setData(data);
    }
  };

  useEffect(() => {
    transformData();
  }, [reportData?.data?.data]);

  return (
    <div
      className={`bg-[#FEFCFB] rounded-xl w-full pt-1 h-[390px] shadow-lg `}
      style={{ overflow: "hidden" }}
    >
      <h2
        className="text-[25px] mb-[8px] p-1 text-black font-normal"
        style={{ fontFamily: "Signika", height: "10%" }}
      >
        Pro Formas
      </h2>
      <div className="border-t-4 border-[#EFEFF4]" style={{ height: "90%" }}>
        {isLoading ? (
          <h1 className="text-white flex justify-center mt-12">
            <Button isLoading={isLoading} title="" variant="light4" />
          </h1>
        ) : (
          <>
            <div className="overflow-y-auto" style={{ height: "80%" }}>
              {data?.map((row: any, ind: number) => {
                const newDate = moment(row.createdAt).format("MM/DD/YYYY");
                return (
                  <div
                    className="flex items-center justify-around p-2"
                    key={ind}
                  >
                    <div className="flex items-center gap-2">
                      <img className="w-[32px] h-[32px]" src={PdfImg} alt="" />
                      <p className="text-[15px]">
                        <span>{lastName}</span>
                        {" - "}
                        <span>${row?.inputId?.equalityContribution}</span>
                        {" - "}
                        <span>{newDate}</span>
                      </p>
                    </div>
                    <div className="flex justify-center"></div>
                    <div className="flex">
                      <button
                        className={`text-[#395849] font-bold rounded-sm text-[15px] px-[8px] mx-2`}
                        title="VIEW"
                        onClick={() => {
                          handleSubmitView(row.inputId);
                        }}
                      >
                        VIEW
                      </button>
                      <button
                        onClick={() => handleDownload(row)}
                        className="text-[#395849] font-bold rounded-sm text-[15px] px-[8px]"
                      >
                        DOWNLOAD
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-center">
              <button
                className="bg-[#356C58] text-white mt-[6px] p-3 rounded-sm text-[12px] "
                onClick={handleProma}
                style={{ height: "20%" }}
              >
                RUN A NEW PRO FORMA
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Tile3;
