import CustomInput from "../../CustomInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { coHostSchema } from "../../../yup";
import { colors } from "../../../utils/theme";
import CustomButton from "../../Button/CustomButton";
import styles from "./index.module.css";
import { RootState, useAppSelector } from "../../../store/store";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAddCohostMutation,
  useCohostFileUploadMutation,
  useDeleteAttachmentsMutation,
  useGetCohostFileNameQuery,
  useUpdateCohostMutation,
} from "../../../services/epcApi";
import { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { config } from "../../../configs/index";
import { showNotification } from "../../../services/showNotification";

const CohostForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    resetField,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(coHostSchema),
  });
  const navigate = useNavigate();
  const EPC = useAppSelector((state: RootState) => state.epcUser.crmContactId);
  const [addCohost, { isLoading, isError, isSuccess }] = useAddCohostMutation();
  const [deleteAttachment, { data: deleteResponse, isLoading: deleteLoading }] =
    useDeleteAttachmentsMutation();
  const [updateCohost, { isLoading: uLoading }] = useUpdateCohostMutation();
  const [fileUpload] = useCohostFileUploadMutation();

  const location = useLocation();
  const { projectId, cohostId, firstName, lastName, email, id } =
    location.state || "";
  const { data: cohostFileData } = useGetCohostFileNameQuery(id, {
    skip: !id,
  });

  const [fileLabel, setFileLabel] = useState("Please attach a file here");
  const [fileLoading, setFileLoading] = useState(false);
  const [cohostDocId, setCohostDocId] = useState("");

  const onSubmit = async (values: any) => {
    console.log(values, "submitted values");
    try {
      const sendData = {
        crm_Contact_ID: cohostId,
        First_Name: values.firstName,
        Last_Name: values.lastName,
        Email: values.email,
        crm_Project_Opportunity_ID: projectId,
        id: cohostDocId,
        EPC,
      };

      let result;
      if (cohostId) {
        result = await updateCohost(sendData).unwrap();
        if (result.data.code != "success") {
          showNotification("error", "Failed to update cohost");
          throw new Error(result.message);
        }
      } else {
        result = await addCohost(sendData).unwrap();
        if (result?.data.status !== 200) {
          showNotification("error", "Failed to created cohost");
          throw new Error(result.message);
        }
      }
      showNotification("success", "Cohost created successfully");
      reset();
      navigate("/epc/dashboard");
    } catch (err) {
      console.log("error", err);
    }
  };

  // const handleFileChange = (event: any) => {
  //   const files = event.target.files;
  //   console.log(files, "files");
  //   if (files && files.length > 0) {
  //     const file = files[0];
  //     setFileLabel(file.name);
  //   } else {
  //     setFileLabel("Please attach a file here");
  //   }
  // };

  const removeFileFromRegister = async (e: any) => {
    e.stopPropagation();
    const response = await deleteAttachment({
      record_id: EPC,
      attachment_id: cohostDocId,
      crmProjectOpportunityId: projectId,
    });
    setCohostDocId("");

    resetField("cosignerId");
    setFileLabel("Please attach a file here");
  };

  const uploadFile = async (file: any) => {
    setFileLoading(true);
    const formData = new FormData();
    formData.append("file", file?.[0]);
    formData.append("crmContactId", EPC);
    formData.append("crmProjectOpportunityId", projectId);
    try {
      // const res = await fetch(`${config.REACT_APP_BASE_URL}/api/epc/upload-file-contact`, {
      //   method: "POST",
      //   body: formData,
      // });
      // const result = await res.json();
      const result: any = await fileUpload(formData);
      if (result.data.data.data[0].status == "success") {
        setCohostDocId(result.data.data.data[0].details.id);
      }
    } catch (error) {
      setCohostDocId("");
      resetField("cosignerId");
      setFileLabel("Please attach a file here");
      showNotification("error", "File not uploaded");
      console.log(error);
    }

    setFileLoading(false);
  };

  useEffect(() => {
    const file: any = getValues("cosignerId");
    if (file?.[0] != null) {
      setFileLabel(file?.[0]?.name);
      uploadFile(file);
    }
  }, [watch("cosignerId")]);

  useEffect(() => {
    if (location.state) {
      reset({
        firstName,
        lastName,
        email,
      });
      if (cohostFileData) {
        setCohostDocId(id);
        setFileLabel(cohostFileData?.data.name);
      }
    }
  }, [location.state, reset, cohostFileData]);

  return (
    <div className={` ${styles.content}`}>
      <p className={`text-[${colors.green}] font-normal text-xl`}>
        Enter details to create Co-Host
      </p>
      <form
        className={`flex flex-col md:w-1/3`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={`w-full ${errors.firstName ? "my-1" : "my-2"} `}>
          <label className="text-white font-bold">First Name*</label>
          <CustomInput
            placeholder=""
            name="firstName"
            type="text"
            register={register}
            error={errors.firstName}
          />
        </div>
        <div className={`w-full ${errors.lastName ? "my-1" : "my-2"} `}>
          <label className="text-white font-bold">Last Name*</label>
          <CustomInput
            placeholder=""
            name="lastName"
            type="text"
            register={register}
            error={errors.lastName}
          />
        </div>
        <div className={`w-full ${errors.email ? "my-1" : "my-2"} `}>
          <label className="text-white font-bold">Email*</label>
          <CustomInput
            placeholder=""
            name="email"
            type="text"
            register={register}
            error={errors.email}
          />
        </div>
        <div
          className={`w-full flex flex-col ${errors.email ? "my-1" : "my-2"} `}
        >
          <label className="text-white font-bold">ID</label>
          <div className="flex w-full">
            <input
              placeholder="Please attach a file here"
              id="fileInput"
              {...register("cosignerId")}
              // onChange={handleFileChange}
              style={{ display: "none" }}
              type="file"
            />
            <label
              htmlFor="fileInput"
              className={`px-4 outline-none w-full h-[42px] font-raleway text-sm bg-white rounded-s-sm flex justify-start items-center cursor-pointer`}
            >
              {fileLoading ? "Uploading..." : fileLabel}
            </label>
            <div className="px-4 outline-none h-[42px] font-raleway text-sm bg-white rounded-e-sm flex items-center justify-start cursor-pointer">
              {!fileLabel?.includes("Please attach a file") && !fileLoading && (
                <MdDelete
                  onClick={(e) => removeFileFromRegister(e)}
                  className="text-xl text-red-700"
                />
              )}
            </div>
          </div>
          {errors.cosignerId && (
            <span className="text-red-500 text-sm w-[100%] mt-1  font-bold flex items-start justify-start capitalize">
              {errors.cosignerId.message}
            </span>
          )}
        </div>
        <div className="w-full flex flex-col items-center justify-center">
          <CustomButton buttonLabel="Create Co-Host" isLoading={isLoading} />
        </div>
        {isError && <p className="text-red-600">Failed to add cohost</p>}
        {isSuccess && (
          <p className="text-green-600">Form submitted successfully</p>
        )}
      </form>
      <button
        onClick={() => {
          navigate("/epc/dashboard");
        }}
        className={`underline self-center text-[${colors.green}] text-lg mt-5`}
      >
        Back to dashboard
      </button>
    </div>
  );
};

export default CohostForm;
